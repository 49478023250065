/// <reference path="../aisc.scss" />

.site-footer {
	@extend .clearfix;

	background-color: #fff;
	border-top: 1px solid $border-color;
	text-align: center;
}

.site-footer .inner {
	padding: 2em 20px;
}

.site-footer-address {
	font-size: size(small);
	text-transform: uppercase;

	@include query(bravo) {
		float: right;
		text-align: left;
	}

	@include query(charlie) {
		font-size: size(base);
		margin-top: 1em;
	}
}

.site-footer-address .contact-list {
	@extend %unstyled-list;	
	margin: 0;
}

.site-footer-address li {
	display: inline-block;
	margin-bottom: 0.25em;

	@include query(charlie) {
		margin-bottom: 0;
	}
}

.site-footer-address li:first-child {
	display: block;

	@include query(charlie) {
		display: inline-block;
	}
}

.site-footer-address li:last-child {
	display: block;

	@include query(bravo) {
		display: inline-block;
		border-left: 1px solid $gray3;
		margin-left: 0.75em;
		padding-left: 1em;
	}
}

.site-footer-copyright {
	background-color: $gray4;
	color: $gray1;
	font-size: size(small);
	padding: 1.5em 20px;
	text-align: center;
}

.site-footer-copyright p {
	margin: 0 0 0.5em 0;

	@include query(bravo) {
		display: inline-block;
		margin-bottom: 0;
		vertical-align: top;
	}
}

.site-footer-copyright a {
	color: $gray1 !important;
	display: inline-block;
	margin: 0 0.5em;
	text-decoration: underline;
	text-transform: lowercase;
	vertical-align: top;

	&:hover,
	&:focus {
		color: #fff !important;
		text-decoration: none;
	}
}

.site-footer-name {
	$grad-start: #d8dad9;

	background-color: $grad-start;
	background-image: -webkit-radial-gradient(circle farthest-side, mix($grad-start, #fff, 50%) 0, $grad-start 100%);
	background-image: radial-gradient(circle farthest-side, mix($grad-start, #fff, 50%) 0, $grad-start 100%);
	overflow: hidden;
	position: relative;
}

.site-footer-name .inner {
	padding: 2em 20px;

	@include query(alpha) {
		padding: 3em 20px;
	}
}

.site-footer-name .logo, .site-footer-name .footer-logo {
    display: inline-block;
    max-width: 90px;
    vertical-align: middle;

    @include query(alpha) {
        max-width: 110px;
    }
}

.site-footer-name .title {
	border-bottom: 1px solid $gray3;
	display: inline-block;
	font-weight: 600;
	margin: 0;
	margin-bottom: 0.5em;
	padding-bottom: 0.5em;
	position: relative;
	vertical-align: middle;
	z-index: 2;

	@include query(bravo) {
		font-size: size(large);
		margin-bottom: 0.375em;
		padding-bottom: 0.375em;
	}

	@include query(charlie) {
		font-size: size(xlarge);
		margin-bottom: 0.25em;
		padding-bottom: 0.25em;
	}
}

.site-footer-name .tagline {
	margin-top: 1em;

	@include query(alpha) {
		display: inline-block;
		font-size: size(big);
		margin: 0 0 0 1em;
		vertical-align: middle;
	}

	@include query(bravo) {
		font-size: size(large);
	}
}

.site-footer-social {
	margin-bottom: 1.25em;
	padding: 0 20px;

	@include query(bravo) {
		float: left;
		margin-bottom: 0;
	}
}