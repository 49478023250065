img, object, embed {
	height: auto !important;
	max-width: 100%;
}

iframe {
	border: none;
	//height: 600px;
	max-width: 100%;
	width: 100%;
}

.video {
	position: relative;
}

.video > iframe {
	left: 0;
	height: 100%;
	position: absolute;
	top: 0;
	width: 100%
}