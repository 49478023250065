// Slideshow
// ======

@mixin carousel-arrow-hover {
	background: #fff;
	box-shadow: 0 1px 3px 1px rgba(0,0,0,0.08);
}

.slick-next.has-icon:hover,
.slick-next.has-icon:focus {
	@include query(charlie) {
		border-bottom-right-radius: 3px;
		border-top-right-radius: 3px;

		@include carousel-arrow-hover;
	}
}

.slick-prev.has-icon:hover,
.slick-prev.has-icon:focus {
	@include query(charlie) {
		border-bottom-left-radius: 3px;
		border-top-left-radius: 3px;

		@include carousel-arrow-hover;
	}
}

.slick-dots li {
	margin: 0;
}

.slick-dots li button:before {
	font: normal normal normal 14px/1 FontAwesome;
	line-height: 20px;
	position: absolute;
	top: 0;
	left: 0;
	width: 20px;
	height: 20px;
	content: '\f0c8';
	text-align: center;
	// opacity: .25;
	opacity: 1;
	color: $gray2;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
	@include themed {
		color: $theme;
	}

	opacity: 1;
}

.homepage-slide .caption--text p, .homepage-slide .caption--text h2 {
	@include themed {
		color: $overlayText;
	}

	padding-bottom: 10px;
	font-weight: normal;
	margin-top: 0;
	margin-bottom: 0.25em;
}

.homepage-slide .slick-dots {
	position: absolute;
	bottom: 0;
	display: block;
	width: 100%;
	list-style: none;
}

.caption {
	padding: 1em;
	bottom: 0;
}

.captionImg {
	background-size: cover;
	z-index: 0;
	bottom: 0;
	height: 100%;
	right: 0;
	position: absolute;
	width: 25%;
	-moz-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
	filter: FlipH;
	-ms-filter: "FlipH";
}

.slideshow .caption {
	width: 100%;
	padding: 1em 50px;

	@include themed {
		background: $theme;
		color: $overlayText;
	}
}

.slideshow {
	margin-left: auto;
	margin-right: auto;
	max-width: 1196px;
	z-index: 10;
}

.slideshow .slide {
	background-size: cover;
	outline: none;

	@include query(charlie) {
		padding-bottom: 0;
	}
}

.featured--content__text h2 {
	color: black;
}

.featured--content {
	position: relative;

	img {
		display: block;
	}
}

.featured--content__text {
	margin: 0 0 0 5em;
	padding: 5em 0 0 0.5em;
	position: absolute;
	top: 0;
	width: 25%;
	right: 0;
	bottom: 0;
	background: #F1F1F1;
}

.slide {
	position: relative;
}


@include query(charlie) {
	.slideshow--bgs {
		z-index: 1;
	}

	.slideshow--mask {
		background: rgba(237, 241, 245,.8);
		z-index: 9;
	}

	.slideshow--mask,
	.slideshow--bg-1,
	.slideshow--bg-2 {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	.slideshow--bg-1,
	.slideshow--bg-2 {
		background-size: cover;
	}

	.slideshow--bg-1 {
		z-index: 5;
	}

	.slideshow {
		img {
			margin: 0 auto;
			max-width: 1196px;
		}

		.caption--wrapper {
			max-width: $layout-width;
			position: relative;
			margin: 0 auto;
		}

		.caption {
			position: absolute;
			bottom: 20px;
			left: 20px;
			width: 400px;
			padding: 1em;
		}
	}
}

.gallery-with-thumbs {
	padding: 0 20px;

	@include query(bravo) {
		margin-bottom: 10px;
		padding: 0;
	}
}

.gallery-with-thumbs .slide img {
	margin: 0 auto;
	max-height: 735px;
}

.gallery-with-thumbs .slick-prev {
	left: -20px;

	@include query(bravo) {
		display: none !important;
	}
}

.gallery-with-thumbs .slick-next {
	right: -20px;

	@include query(bravo) {
		display: none !important;
	}
}

.gallery-thumbs {
	display: none;

	@include query(bravo) {
		display: block;
	}
}
.gallery-thumbs {
    .slick-track {
        display: flex;

    }
}
.gallery-thumbs .slide {
	background: $gray6;
	cursor: pointer;
	margin-right: 5px;
   
}

.gallery-thumbs img {
	opacity: 0.5;
	-moz-transition: all 200ms ease-out;
	-o-transition: all 200ms ease-out;
	-webkit-transition: all 200ms ease-out;
	transition: all 200ms ease-out;
    height: 200px !important;
}

.gallery-thumbs .slick-current img {
	opacity: 1;
}

.gallery-caption {
	margin-top: 1em;

	@include query(bravo) {
		background: rgba($gray6, 0.5);
		bottom: 0;
		color: #fff;
		left: 0;
		margin-top: 0;
		padding: 1em 1.5em;
		position: absolute;
		width: 100%;
	}
}
