// Accordions
// ======

.accordion .item {
	border: 1px solid $border-color;
	border-bottom: none;
}

.accordion .item:last-child {
	border-bottom: 1px solid $border-color;
}

.accordion .header {
	background: $gray1;
	margin: 0;
	padding: 1em 3em 1em 1em;
	position: relative;

	.sprite {
		position: absolute;
		right: 1em;
		top: 1em;
	}
}

.accordion .info,
.accordion .more {
	border-top: 1px solid $border-color;
	background: #fff;
	padding: 1em;

	p:first-child {
		margin-top: 0;
	}

	p:last-child {
		margin-bottom: 0;
	}
}

.accordion .info + .more {
	border-top: none;
	padding-top: 0;
}


