.pagination ul {
	@extend %unstyled-list;
	@extend %sidekick;
}

.pagination li {
	display: none;
	
	@include query(alpha) {
		display: inline-block;
		margin: 0 0.125em;
	}
}

.pagination .prev,
.pagination .next,
.pagination .PagedList-pageCountAndLocation {
	display: block;
	float: left;
	width: 1/3 * 100%;
	
	@include query(alpha) {
		display: inline-block;
		float: none;
		width: auto;
	}
}

.pagination .next {
	text-align: right;
}

.pagination .PagedList-pageCountAndLocation {
	text-align: center;

	@include query(alpha) {
		display: none;
	}
}

.pagination .PagedList-pageCountAndLocation a {
	color: $gray6 !important;
}

.pagination a {
	border-radius: 3px;
	display: inline-block;
	padding: 0.5em 0.75em;
	text-decoration: none;
	-moz-transition: all 200ms ease-out;
	-o-transition: all 200ms ease-out;
	-webkit-transition: all 200ms ease-out;
	transition: all 200ms ease-out;
	
	@include query(alpha) {
		display: block;
	}
}

.pagination a:hover {
	@include themed {
		color: #fff;
		background: $theme;
	}
}

.pagination .disabled a,
.pagination .disabled a:hover {
	@include themed {
		background: none;
		color: $gray3;
	}
}


.pagination .active {
	@include query(alpha) {
		background: $gray1 !important;
		border: 1px solid $border-color;
		border-radius: 3px;
		color: $gray6 !important;
		display: inline-block;
		padding: 0.5em 0.75em;
	}
}
