// Cart and Checkout
// =============

.shopping--Cart ul {
	border-top: 1px solid $border-color;
	margin-bottom: 1em;
}

.shopping--Cart .accordion ul {
	border-top: none;
}


.shopping--Cart li {
	border-bottom: 1px solid $border-color;
	margin: 0;
	padding: 1.5em 1.85em 1.5em 0;
	position: relative;
}

.shopping--Cart .media {
	display: table-row;
}

.shopping--Cart .media .img {
	max-height: 96px;
	margin-right: 20px;
	max-width: 67px;
	display: table-cell;
}

.shopping--Cart .media .bd {
	display: table-cell;
	vertical-align: top;
}

.detail--one {
	margin-bottom: 10px;
}

.detail--two {
}

.shopping--Cart h3 {
	margin-top: 0;
	margin-bottom: 5px;
	font-size: 1em;
	line-height: 1.25;
}

.shopping--Cart h4 {
	font-weight: 600;
	text-align: left;
}

.shopping--Cart .product--Price {
	/*  margin-bottom:.5em;
  display:inline-block;*/
	display: block;
}

.shopping--Cart .product-format {
	display: block;
}

.shopping--Cart-quantitiy {
}

.move-bookmark {
	margin-top: 10px;
}

.shopping--Cart-quantity {
	display: inline-block;
	margin-right: 0.5em;
}

.shopping--Cart-quantity input {
	background: #fff;
	border: 1px solid $border-color;
	border-radius: 0;
	padding: 0.5em .25em;
	text-align: center;
	width: 2.5em;
	-webkit-appearance: none;
	font-size: 16px;

	@include query(charlie) {
		font-size: 14px;
	}
}

.shopping--Cart-quantity input[type="button"] {
	background: $gray1;

	@include themed {
		color: $theme;
	}
}

.shopping--Cart-quantity input[type="button"]:hover,
.shopping--Cart-quantity input[type="button"]:focus {
	background: #fff;
}

.shopping--Cart-quantity input[type="text"] {
	border-left: none;
	border-right: none;

	&[disabled] {
		border: none;
		padding: 0;
	}
}

.cart--actions {
	@include query(alphaMax) {
		input {
			display: block !important;
			width: 100% !important;
			margin-bottom: 0.5em !important;
		}
	}

	@include query(charlie) {
		input {
			display: block !important;
			width: 100% !important;
			margin-bottom: 0.5em !important;
		}
	}

	.cb {
		background: $light;
		padding: 1em;
		margin-bottom: 1em;

		p {
			margin-bottom: 0;
		}
	}
}

.coupon--Code {
	position: relative;
}

.coupon--Code input[type="text"] {
	display: inline-block;
	background: #FFFFFF;
	box-sizing: border-box;
	width: auto;
	border: 1px solid $border-color;
	padding: .65em 2em .65em .65em;
}

.coupon--Code input[type="button"] {
	display: inline-block;
}

input.full-width,
select.full-width {
	box-sizing: border-box;
	display: block;
	width: 100%;
	margin-bottom: 1em;
}

.shipping--Options li,
.expanding--List li {
	border: 1px solid $border-color;
	border-top: none;
	padding: 1em;

	.input-wrapper {
		display: table;
		/*width: 100%;*/
		input[type="checkbox"],
		input[type="radio"],
		input[type="checkbox"] + label,
		input[type="radio"] + label {
			display: table-cell;
		}
	}
}

.shipping--Options li:first-child,
.expanding--List li:first-child {
	border-top: 1px solid $border-color;
}

.shipping--Options li.active,
.payment--Options li.active,
.expanding--List li.active {
	/*background: #F5F6F7;*/
}

.payment--Options .select.variable-width {
	display: inline-block;

	select {
		padding-right: 28px;
	}
}

.js .payment--Options .more,
.js .expanding--List .more {
	display: none;
}

.js .payment--Options li.active .more,
.js .expanding--List li.active .more {
	display: block;
}

.confirmation .shopping--Cart-quantity input[type="button"],
.confirmation .update--Cart {
	display: none;
}

.checkout--Indicator li {
	/*border-top: 1px solid #CCC;*/
	/*border-bottom: 1px solid #CCC;*/
	/*border-right: 1px solid #CCC;*/
	/*width: 2em;
  height: 1em; */
	margin: 0;
	/*padding:.25em 1em;*/
	padding: 0 1em 1em 0;
	/*font-size:.87em;*/
}

.checkout--Indicator li:first-child {
	/*border-left: 1px solid #CCC;*/
	/*border-radius: 5px 0 0 5px;*/
}

.checkout--Indicator li:last-child {
	/*border-radius: 0 5px 5px 0;*/
}

.checkout--Indicator li.active {
	/*background: #CCC;*/
	font-weight: bold;
}

a.removeItem {
	position: absolute;
	color: #677074 !important;
	top: -1px;
	right: -1px;
	border: 1px solid rgba(0,0,0,.15);
	padding: 2px 6px;
	border-bottom-style: solid !important;
	background: #F5F6F7;
}

a.removeItem:hover {
	position: absolute;
	color: #942020 !important;
	top: -1px;
	right: -1px;
	border: 1px solid #DECBCB;
	padding: 2px 6px;
	border-bottom-style: solid !important;
	background: #F0E2E2;
}

.memberPriceOut {
	text-transform: none;
	color: $primary;
}

.memberPriceIn {
	text-transform: none;
	color: $primary;
	text-decoration: none;
	float: left;
}

.nonMemberPriceOut {
	text-transform: none;
	color: $primary;
	float: left;
}

.nonMemberPriceIn {
	text-transform: none;
	color: $primary;
	text-decoration: none;
	float: left;
}

.shopping--cart-wrapper {
	max-width: 900px;
}

fieldset.checkout {
	padding: 1em;
	border: 1px solid $border-color;
	margin-top: 1em;
}

p.shippingText {
	font-size: 12px;
}

input.input-3 {
	max-width: 5em;
}

input.input-5 {
	max-width: 7em;
}

.order-confirmation a {
	margin-left: 1em;

	&:first-child {
		margin-left: 0;
	}
}

.header-cart {
	position: relative;
}

.cart-modal {
	background: #fff;
	opacity: 0;
	padding: 1.5em;
	position: absolute;
	top: 0;
	-moz-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	-o-transform: translateY(-100%);
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%);
	-moz-transition: 300ms all ease-out;
	-o-transition: 300ms all ease-out;
	-webkit-transition: 300ms all ease-out;
	transition: 300ms all ease-out;
	right: 0;
	width: 100%;
	z-index: 190;

	@include query(alpha) {
		width: 20em;
	}
}

.cart-modal.show {
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.06), 0 6px 18px rgba(0, 0, 0, 0.06);
	opacity: 1;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	-webkit-transform: none;
	transform: none;
}

.cart-modal p {
	margin-top: 0;
}

.cart-modal .button {
	height: auto;
	margin-bottom: 0;
	padding: 0.5em 1.375em;
}

.discount-code {
	display: block;
	margin: 0.25em 0;
}

.discount-code .remove {
	cursor: pointer;
}

.final-price {
	@include query(charlie) {
		text-align: right;
	}
}