// Hero
// =======

.hero {
    position: relative;
    text-align: center;

    @include query(charlie) {
        margin-bottom: -10px;
    }

    .more {
        border-radius: 100%;
        bottom: 0;
        box-shadow: 0 2px 8px rgba(0,0,0,0.2);
        color: #fff;
        font-size: 1.5em;
        height: 2em;
        left: 50%;
        line-height: 2em;
        margin-bottom: -1em;
        margin-left: -1em;
        position: absolute;
        width: 2em;
        z-index: 99;

        @include themed {
            background: $theme;
        }

        &:hover {
            background: #fff !important;
        }

        @include query (bravo) {
            font-size: 2em;
        }
    }
}

.hero-bg {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.08);
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    z-index: 1;
}

.no-image .hero-bg {
    bottom: 0;
    box-shadow: none;
}

.hero-image {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: auto;
    margin-right: auto;
    max-width: $layout-width;
    padding: 2em 1em;
    position: relative;
    z-index: 2;

    @media screen and (min-width: 52em) and (max-width: 75em){
      padding-top: 6em;
    }

    @include query(charlie){
      /*box-shadow: 0 2px 8px rgba(0,0,0,.2);*/
      padding: 9.5em 0 7em;
    }
}

.no-image .hero-image {
    background-image: none !important;
    box-shadow: none;
}


.hero.no-image {
    @include query(charlie) {
        margin-bottom: 0;
    }

    &.interior {
      .hero-image {
        padding-top: 2em;

      @media screen and (min-width: 832px) and (max-width: 75em){
        padding-top: 6em;
      }

        @include query(charlie){
          padding-top: 12em;
        }
      }
    }
}

.hero.interior {
  &.tall-nav {
    .hero-image {
      padding-top: 2em;

      @media screen and (min-width: 832px) and (max-width: 922px){
        padding-top: 10.5em;
      }

      @media screen and (min-width: 922px) and (max-width: 64em){
        padding-top: 8em;
      }

      @media screen and (min-width: 64em) and (max-width: 75em){
        padding-top: 6.5em;
      }

      @include query(charlie){
        padding-top: 12em;
      }
    }
  }
}


.hero-links {
    @extend .vlist;
    @extend .clearfix;
    margin: 0 auto;
    max-width: 35em;

    @include query(bravo) {
        display: block;
        margin-top: 2.5em;
        max-width: 1000px;
        vertical-align: middle;
    }
}

.hero-links li {
    margin: 0.5em 0;
}


.hero-links a {
    @extend .button;
    border: 1px solid rgba(255, 255, 255, 0.5) !important;
    display: block;
    padding-bottom: 0.75em;
    padding-top: 0.75em;
    width: 100%;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0 1px 2px rgba(0,0,0,0.5);
    -moz-transition: all 125ms ease-out;
    -o-transition: all 125ms ease-out;
    -webkit-transition: all 125ms ease-out;
    transition: all 125ms ease-out;

    @include themed {
        background-color: rgba(mix(mix($theme, #000, 70%), #fff, 80%), 0.75);
    }

    @include query(bravo) {
        border: none !important;
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.8);
    }

    @include query(charlie) {
        max-width: 800px;
    }
}

.hero-links a:before {
    @include query(bravo) {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
}

.hero-links a:hover,
.hero-links a:focus {
    background: #fff !important;
    position: relative;
    text-shadow: none;
    z-index: 1;

    @include themed {
        color: $theme-light !important;
    }

    @include query(bravo) {
        box-shadow: 0 15px 12px rgba(0,0,0,0.22), 0 19px 38px rgba(0,0,0,0.3);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}

.hero-links .title {
    @include query(bravo) {
        display: inline-block;
        font-size: size(base);
        font-weight: 700;
        vertical-align: middle;
    }

    @include query(charlie) {
        font-size: size(medium);
    }
}
.page-title {
    color: #fff !important;
    font-size: size(large);
    font-weight: 700;
    margin: 0;
    text-shadow: 0 0.05em 0.125em rgba(0,0,0,0.5);
    text-transform: uppercase;

    @include query(bravo) {
        font-size: size(xlarge);
    }

    @include query(charlie) {
        font-size: size(xxlarge);
    }
}

.page-title + .hero-links {
  margin-top: 2em;
}

.page-title:last-child {
    @include query(bravo) {
        margin-bottom: 1em;
    }

    @include query(charlie) {
        margin-bottom: 3.5rem;
    }
}

.image-caption {
    display: none;

    @include query(bravo) {
        //bottom: 1.5em;
        display: block;
        font-size: size(small);
        left: 20px;
        //position: absolute;
    }

    @include query(charlie) {
        left: 2em;
    }

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.image-caption .fa {
    background-color: rgba($gray3, 0.5);
    border-radius: 100%;
    color: #fff !important;
    font-size: size(big);
    height: 2em;
    line-height: 2;
    margin-right: 0.25em;
    text-shadow: 0 1px 2px rgba(0,0,0,0.5);
    vertical-align: middle;
    width: 2em;

    @include query(charlie) {
        margin-right: 0;
    }
}

.image-caption:hover .fa,
.image-caption:focus .fa {
    background-color: rgba($gray2, 0.65);
}

.image-caption-text {
    color: #fff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    text-transform: uppercase;

    @include query(charlie) {
        background: #fff;
        border-radius: 3px;
        bottom: 100%;
        box-shadow: 0 1px 3px 1px rgba(0,0,0,0.08);
        color: $primary;
        left: -1em;
        margin-bottom: 11px;
        opacity: 0;
        padding: 1em;
        position: absolute;
        text-align: center;
        text-shadow: none;
        -moz-transform-origin: 15% 100%;
        -ms-transform-origin: 15% 100%;
        -o-transform-origin: 15% 100%;
        -webkit-transform-origin: 15% 100%;
        transform-origin: 15% 100%;
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        -webkit-transform: scale(0);
        transform: scale(0);
        -moz-transition: all 150ms ease-out;
        -o-transition: all 150ms ease-out;
        -webkit-transition: all 150ms ease-out;
        transition: all 150ms ease-out;
        -moz-transition-delay: 200ms;
        -o-transition-delay: 200ms;
        -webkit-transition-delay: 200ms;
        transition-delay: 200ms;
        width: 16em;
        z-index: 200;

        &:after {
            content: '';
            border-color: #fff transparent transparent transparent;
            border-style: solid;
            border-width: 6px 6px 0 6px;
            height: 0;
            left: 1.75em;
            position: absolute;
            top: 100%;
            width: 0;
        }
    }
}

.image-caption:hover .image-caption-text,
.image-caption:focus .image-caption-text {
    opacity: 1;
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
}

.image-caption-cta {
    @include query(charlie) {
        @include themed {
            color: $theme;
        }
    }
}

.image-caption-cta:hover {
    @include query(charlie) {
        @include themed {
            color: $theme-dark;
            text-decoration: underline;
        }
    }
}
