.sponsor-logos {
	@extend %unstyled-list;
}

.sponsor-logos li {
	margin: 0 auto;
	max-width: 20em;
	padding: 1em;
	text-align: center;
	vertical-align: middle;

	@include query(alpha) {
		display: inline-block;
		width: 49%;
	}

	@include query(bravo) {
		width: 32%;
	}

	@include query(charlie) {
		width: 24%;
	}
}

.sponsor-logos a {
	display: inline-block;
	vertical-align: middle;
}

.sponsor-logos img {
	display: block;
	margin: 0 auto;
	max-height: 10em;
}