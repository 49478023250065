// Portal Links Certification
// ========

.portalLink--backsplash {  
  background:#dff1f8;
  text-align:center;
  background-size: cover;
  background-position-y: 30%;
}

.portalLink--backsplash_dark {
    padding: 1em;
    background: #b5dfef;
    text-align: center;
}

.certificationScreen h4 a {
    color: #ffffff;
    font-size: 26px;
    text-transform: uppercase;
    text-align: center;
    font-weight: normal;
    -webkit-text-shadow: 0 1px 2px #000000;
    -moz-text-shadow: 0 1px 2px #000000;
    -ms-text-shadow: 0 1px 2px #000000;
    -o-text-shadow: 0 1px 2px #000000;
    text-shadow: 0 1px 2px #000000;
}
