// Type
// ====

$fonts: () !default;

@mixin font($family, $weight: null, $style: null, $size: null) {
  font-family: map-get(map-get($fonts, $family), stack);
  @if $size { font-size: if(type-of($size) == number, $size, size($size)); }
  @if $weight {
    $num: map-get(map-get(map-get($fonts, $family), weights), $weight);
    @if $num { font-weight: $num; } @else { @warn "`#{$family}` has no `#{$weight}` listed."; }
  }
  @if $style {
    @if $weight == null { $weight: normal; }
    @if index(map-get(map-get(map-get($fonts, $family), styles), $weight), $style) { font-style: $style; } @else { @warn "`#{$family}` has no `#{style}` style for the `#{weight}` weight."; }
  }
}
