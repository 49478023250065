/// <reference path="../aisc.scss" />

// FORMS
// =======

*:focus {
    outline: none;
}

fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

legend {
    @extend %label;
    display: block;
    margin-bottom: 1em;
}

label {
    @extend %sidekick;
    display: block;
    margin-bottom: 0.5em;
}

%field {
    background: #fff;
    border: 1px solid $gray3;
    border-radius: 3px;
    color: $gray5;
    font-size: size(medium);
    padding: 0.5em 1em;
    width: 100%;

    @include appearance(none);

    &:focus {
        border-color: $gray5;
    }

    &:disabled {
        background: rgba(#000, .03);
        border-color: rgba(#000, .1);
    }

    @include query(charlie) {
        font-size: size(base);
    }
}

.mod-alt %field {
    background: rgba(0,0,0,0.2);
    border-color: transparent;
    border-width: 2px 2px 1px 2px;
    color: rgba(255, 255, 255, 0.85);

    &:focus {
        background: rgba(0,0,0,0.3);
    }
}

%tick {
    clear: left;
    float: left;
    margin-bottom: 0;
    margin-right: 0.45em;
    margin-top: 0.375em;

    & + label {
        display: inline-block;
        font-size: size(base);
        margin-bottom: 0;
        vertical-align: baseline;
    }
}

.check-field label {
    clear: none;
    color: $primary;
    display: block;
    font-size: size(base);
    font-weight: 400;
    letter-spacing: normal;
    margin-bottom: 0;
    padding-top: 0.25em;
    padding-bottom: 1em;
    text-transform: none;
    vertical-align: baseline;
}

input:not([type]), input[type="text"],
input[type="password"], input[type="date"],
input[type="datetime"], input[type="datetime-local"],
input[type="month"], input[type="week"],
input[type="email"], input[type="number"],
input[type="search"], input[type="tel"],
input[type="time"], input[type="url"],
input[type="color"], input[type="file"],
textarea {
    @extend %field;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: $gray4;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
    color: $gray4;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    color: $gray4;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: $gray4;
}

input.button {
    font-size: size(medium);

    @include query(charlie) {
        font-size: size(base);
    }
}

textarea {
    height: 6.5em;
}

input[type="checkbox"],
input[type="radio"] {
    @extend %tick;
}

input[type="file"] {
    padding-bottom: 1em;
    padding-top: 1em;
}

select {
    option[disabled]:first-child {
        color: $gray4;
    }
}

.select {
    position: relative;

    &::after {
        content: "\f0d7";
        cursor: pointer;
        font-family: "FontAwesome";
        line-height: 1;
        margin-top: -0.5em;
        pointer-events: none;
        position: absolute;
        right: 1em;
        top: 50%;
        z-index: 1000;

        @include themed {
            color: $theme;
        }
    }

    select {
        @extend %field;
        cursor: pointer;
        padding-right: 2em;
        text-transform: uppercase;

        @include background-image(linear-gradient(top, #fff 0%, $gray2 100%));

        option {
            text-transform: none;
        }

        &::-ms-expand {
            display: none;
        }
    }
}

.multiselect-container {
    li {
        margin-left: -26px;
    }
}

.multiselect.dropdown-toggle {
    overflow: hidden !important;
}


.dropdown-toggle {
    width: 100%;
    text-align: left;
    background-image: linear-gradient(to bottom, #fff 0%, $gray2 100%);
    border: 1px solid $gray3;
    padding: 0.572em 1em !important;

    @media screen and (max-width: 1200px) {
        padding: 0.786em 1em !important;
    }

    &:after {
        display: none !important;
    }
}

.dropdown-menu {
    -webkit-padding-start: 0px !important;
    width: 100%;

    li > a > label {
        text-transform: none;
        color: $gray5 !important;

        > input {
            margin-top: 2px !important;
        }
    }
}

.multiselect-selected-text {
    color: #595959;

    @media screen and (max-width: 1200px) {
        font-size: 1.143em;
    }
}

.btn-group {
    width: 100%;
}

.caret {
    display: none !important;
}

.lt-ie10 .select {
    &::after {
        content: none;
    }

    select {
        padding: 0.5em 0.5em 0.5em 1em;
    }
}

.select-multi select {
    @extend %field;
}

.mod-alt .select {
    option {
        background: #fff;
        color: $gray5;
    }

    &::after {
        color: rgba(255,255,255,0.85) !important;
    }
}

// Form list
// ---------

.form {
    @extend %unstyled-list;
    @extend .clearfix;
    margin: 0 -20px;
    padding-right: 20px;

    &-item {
        @extend .clearfix;
        float: left;
        padding-bottom: 20px;
        padding-left: 20px;
        position: relative;
        width: 100%;

        %tick {

            + label {
                clear: none;
                color: $primary;
                display: block;
                font-weight: 400;
                letter-spacing: normal;
                margin-bottom: 0;
                padding-top: 0.25em;
                padding-bottom: 1em;
                text-transform: none;
            }
        }

        &.is-incomplete {
            label {
                color: $color-error;
            }

            %field {
                border-bottom-color: lighten($color-error, 25%);
            }
        }

        &.is-required label::before {
            color: darken($dark, 5%);
            content: "*";
            font-size: size(small);
            margin-right: 0.25em;
        }
    }

    > li {
        @extend .form-item;
    }

    & + & {
        @include query(bravo) {
            border-top: 1px solid $border-color;
            padding-top: 20px;
        }
    }
}

.checkboxes {
    color: $gray4;
}

.field-validation-error {
    bottom: 0.25em;
    color: $color-error;
    display: block;
    font-size: size(small);
    position: absolute;

    @include font($sans, $weight: semibold);
}

@mixin form-message {
    @include font($sans, $weight: semibold);
    border-radius: 3px;
    padding: 1em 1em 1em 2.5em;
    position: relative;

    @include query(alpha) {
        padding: 1.5em 1.5em 1.5em 4em;
    }

    .message-icon {
        left: 1rem;
        position: absolute;
        top: 1.25rem;

        @include query(alpha) {
            font-size: 1.75em;
            left: 1.5rem;
            top: 1.375rem;
        }
    }

    p,
    ul {
        margin: 0;
    }

    ul {
        @extend %unstyled-list;

        li:not(:last-child) {
            margin-bottom: 1em;
        }
    }
}

.form-messages {
    @extend %unstyled-list;
    margin: 0;

    li {
        margin: 0.5em 0;
    }
}

.error-summary {
    @include form-message;
    background: $color-error;
    color: #fff;

    .message-icon:before {
        content: "\f06a";
    }
}

.info-summary {
    @include form-message;
    background: $color-info;

    .message-icon:before {
        content: "\f05a";
    }
}

.success-summary {
    @include form-message;
    background: $color-success;
    color: #fff;

    .message-icon:before {
        content: "\f058";
    }
}

.input-validation-error {
    border-color: $color-error !important;
}

.required:before {
    content: "*";
    color: $color-error;
    margin-right: 0.25em;
}

.forgot-user-pass {
    padding-top: 1em;

    a {
        margin-top: 0.5em;
        display: block;
    }

    @include query(alpha) {
        float: right;

        a {
            display: inline-block;
            margin: 0 0 0 0.5em;
            vertical-align: bottom;
        }
    }
}

@mixin boxshadow ($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    -o-border-radius: $radius;
    border-radius: $radius;
    border: 1px solid rgba(0,0,0,.2);
    -webkit-box-shadow: 0 2px 2px rgba(0,0,0,0.075);
    box-shadow: 0 2px 2px rgba(0,0,0,0.075);
}










/** This file list all CSS classes used by Episerver Forms.

    Class names are structured and named in BEM methodology (Block, Element, Modifier) so you can easily see the overview.
        - CSS classes and their structure defined here are used by Form Core JavaScript heavily.
        - Please keep the structure (parent-child block-element relation) and default class names as is.

    This file is intended to be re-styled by 3rd developers. or in their site template (E.g.: AlloyMVC).
        - Default styling contain only minimal styling-rule for Episerver Forms and its build-in elements.
        - Default styling is applied for both Forms rendering in ViewMode and authoring in EditView.
        - Styling rules can be modified freely.
*/
.EPiServerForms {
    $active-color: $gray6; /*predefined color for selected item, elapsed progress*/
    $invisibleelement-color: $gray2; /*to indicate FormStep, FormHidden in EditView*/
    $border-radius: 4px;
    $warning-foreground-color: $color-error;
    $warning-background-color: #fff;

    .Form__Title {
        @extend .formHeader;
    }

    .Form__Description {
    }

    .FormImageSubmitButton {
        width: 100%;
        height: 100%;
        padding: 0;
        border: 0;
        background: none;
        box-shadow: none;
    }

    .Form__Status {
        margin: 1em 0;
    }

    .Form__Status__Message {
        display: block;
        margin: 1rem 0;
    }

    .Form__Success__Message {
        background: $color-success;
        color: #fff;
    }

    .Form__Warning__Message {
        background-color: $warning-background-color;
        color: $warning-foreground-color;
    }

    .Form__Readonly__Message {
        display: block;
        padding: 1rem;
        margin: 1rem 0;
        background-color: $warning-background-color;
        color: $warning-foreground-color;
    }

    .Form__MainBody {
        @extend .formColorFieldArea;
    }
    // for next or previous navigation between steps
    .Form__NavigationBar {
        .Form__NavigationBar__Action {
            display: inline-block;
            padding: inherit;
            vertical-align: middle;
            text-align: center;
            border: 1px solid $active-color;
            border-radius: $border-radius;
            font-size: inherit;
            height: 2em;
            width: 10em;
            max-width: 10rem;
            color: initial;
            background-image: linear-gradient(top,#fff,#e6e6e6);
            background-image: -moz-linear-gradient(top,#fff,#e6e6e6);
            background-image: -ms-linear-gradient(top,#fff,#e6e6e6);
            background-image: -webkit-linear-gradient(top,#fff,#e6e6e6);

            &:disabled {
                color: $gray3;
            }
        }

        .Form__NavigationBar__ProgressBar {
            display: inline-block;
            border: 1px solid $active-color;
            vertical-align: middle;
            width: 40%;

            .Form__NavigationBar__ProgressBar--Progress {
                /*display: none;*/ /*turn on this style to hide visual progress bar*/
                background-color: $active-color;
                width: 0%; /* Will be adjusted by JavaScript */
                height: 1rem;
            }

            .Form__NavigationBar__ProgressBar--Text {
                display: none; /*turn off this style to show text "Page 1/4" of progress bar*/
                .Form__NavigationBar__ProgressBar__ProgressLabel {
                }

                .Form__NavigationBar__ProgressBar__CurrentStep {
                }

                .Form__NavigationBar__ProgressBar__StepsCount {
                }
            }
        }
    }

    .FormStep {
        @extend .form;
        float: none;
        padding-bottom: 0;
        padding-left: 0;
        width: auto;

        .FormStep__Title {
        }

        .FormStep__Description {
            margin-bottom: 1rem;
        }
    }
    // All Form's Element need to be tag with this class at root DOM node. Form__Element wraps every-DOM-nodes of current form element.
    // For simple element like Textbox, it can be the input
    // For complex element like Selection, Captcha, it contains the Label and input controls for taking value from Visitor
    .Form__Element {
        @extend .form-item;

        &.formColorFoot {
            border-top: none;
            margin-top: 20px;
            padding-top: 0;

            &:before {
                background: $border-color;
                content: '';
                display: block;
                height: 1px;
                line-height: 0;
                margin-bottom: 20px;
                overflow: hidden;
            }
        }
        // change style of Form__Element in the Preview viewport in EditView (it affects both Editting mode and Preview mode)
        .preview & {
        }
        // label for element
        .Form__Element__Caption {
            @extend %sidekick;
            display: block;
            margin-bottom: 0.5em;
        }
        // validation error message for each Element
        .Form__Element__ValidationError {
            color: $warning-foreground-color;
            display: block;
            word-wrap: break-word;
        }
        // SEMANTIC
        // for marking non-data element (like FormStep, ParagraphText, ...)
        .Form__Element--NonData {
        }
        // Element has this class will not be bound with saved Data in LocalStorage
        .FormExcludeDataRebind {
        }
    }
    /************* UTILITY CLASSES ***********/
    // Use serverside or clientside logic to add/remove this class to show/Hide element
    .hide {
        display: none;
    }
    /************* BUILDIN ELEMENTS ***********/
    /******************************************/
    .FormSubmitButton {
        @extend .button;
        float: none;
        width: auto;
    }

    .FormResetButton {
    }

    .FormTextbox {
        .FormTextbox__Input {
        }
    }

    .FormTextbox--Textarea {
        .FormTextbox__Input {
        }
    }

    .FormTextbox--Number {
        .FormTextbox__Input {
        }
    }

    .FormHidden {
    }

    .FormFileUpload {
    }

    .FormCaptcha {
        .FormTextbox__Input {
            width: 8rem;
        }

        .FormCaptcha__Image {
        }

        .FormCaptcha__Refresh {
            font-size: 1em;
            width: auto;
            height: auto;
        }
    }

    .FormSelection {
    }

    .FormChoice {
        label {
            clear: none;
            color: $primary;
            display: block;
            font-weight: 400;
            letter-spacing: normal;
            margin-bottom: 0;
            padding-top: 0.25em;
            padding-bottom: 1em;
            text-transform: none;
        }

        .FormChoice__Input {
            margin-top: 0.125em !important;
        }

        .FormChoice__Input--Checkbox {
            margin-top: 0.125em !important;
        }

        .FormChoice__Input--Radio {
            margin-top: 0.125em !important;
        }
    }

    .FormChoice--Image {
        // hide the input (checkbox or radio) next to the image
        .hidden {
            visibility: hidden;
        }

        .FormChoice--Image__Item {
            /* for image choice items, display as a flow (inline-block), we want max 2 items in one row, with a small margin */
            display: inline-block;
            max-width: 40%;
            text-align: center; /* image caption will be center */
            margin: 0.5rem;

            .FormChoice--Image__Item__Caption {
            }
        }
        /*img stand next to checked image will be bordered*/
        .FormChoice__Input:checked ~ img {
            border: 1px solid $active-color;
        }
    }
    // Range element
    .FormRange {
        // range input type element
        .FormRange__Input {
            max-width: 60%;
            display: inline;
        }
        // min, max label elements
        .FormRange__Min {
        }

        .FormRange__Max {
        }
    }
    /*************** EDIT VIEW ****************/
    /******************************************/
    // Form__Element which is invisible in ViewMode will have this CSS class in EditView (E.g.: FormStep and FormHidden should have this class)
    .EditView__InvisibleElement {

        &.FormStep {
            display: block;
            border-radius: $border-radius;
            border-color: $invisibleelement-color;
            background-color: $invisibleelement-color;
            color: #000;
            padding: 0.2rem;
            font-family: Verdana;
            box-shadow: 3px 3px 5px #ccc;
            /*text-align: center;*/
            .FormStep__Warning {
                @extend .Warning;
            }
        }

        &.FormHidden {
            display: inline-block;
            border-radius: $border-radius;
            border: 1.5px outset $invisibleelement-color;
            padding: 0.2rem 1rem;
            min-width: 18rem;
            /*text-align: center;*/
        }
    }

    .Warning {
        color: $warning-foreground-color;
        background-color: $warning-background-color;
        display: block;
        word-wrap: break-word;
    }

    .Form__Warning {
        @extend .Warning;
    }
}
