// Portal Links Why Steel
// ========

.whySteelScreen h4 a {
    color: #ffffff;
    font-size: 26px;
    text-transform: uppercase;
    text-align: center;
    font-weight: normal;
    -webkit-text-shadow: 0 1px 2px #000000;
    -moz-text-shadow: 0 1px 2px #000000;
    -ms-text-shadow: 0 1px 2px #000000;
    -o-text-shadow: 0 1px 2px #000000;
    text-shadow: 0 1px 2px #000000;
}


.portalLink--whysteel h4 {
    color: #ffffff;
    margin-top: 20%;
    font-size: 34px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    letter-spacing: .1em;
    -webkit-text-shadow: 0 1px 2px #000000;
    -moz-text-shadow: 0 1px 2px #000000;
    -ms-text-shadow: 0 1px 2px #000000;
    -o-text-shadow: 0 1px 2px #000000;
    text-shadow: 0 1px 2px #000000;

}


.portalLink--whysteel a:hover {
  color: #FFFFFF;
}
