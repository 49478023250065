// Print Styles
// ====

@media print {

    *,
    *:before,
    *:after,
    *:first-letter,
    *:first-line {
		background: transparent !important;
		color: black !important;
		text-shadow: none !important;
		filter: none !important;
		-ms-filter: none !important;
		box-shadow: none !important;
	}

	html {
		font-size: 8pt !important;
	}

	a, a:visited {
		@include themed {
			color: #444 !important;
			text-decoration: underline;
		}
	}

	pre, blockquote, .blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group;
	}

	tr, img {
		page-break-inside: avoid;
	}

	@page {
		margin: 0.5cm;
	}

	p, h2, h3 {
		orphans: 3;
		widows: 3;
	}

	h2, h3 {
		page-break-after: avoid;
	}

	.section {
		padding-bottom: 1em !important;
		padding-top: 1em !important;
	}

	h1 {
		font-size: 2em !important;
	}

	h2 {
		font-size: 1.4em !important;
	}

	h3 {
		font-size: 1.2em !important;
	}

	h4, legend {
		font-size: 1em !important;
	}

	.site-footer {
		font-size: 11px !important;
	}

	.top--Bar,
	.toggle--MainNav,
	.offCanvasMenu,
	.hero,
	.steel-search,
	.site-footer-social,
	.site-footer-name,
	.site-footer-copyright a,
	.slick-arrow,
	.section-nav,
	.accordion .header .sprite {
		display: none !important;
	}

	.slick-slide,
	.js .accordion .more {
		display: block !important;
	}

	.slick-track {
		-moz-transform: none !important;
		-ms-transform: none !important;
		-o-transform: none !important;
		-webkit-transform: none !important;
		transform: none !important;
		width: auto !important;
	}

	.slick-slide {
		height: auto !important;
		width: auto !important;
		float: none;
	}

	.feature-carousel {
		margin: 0 !important;
	}

	.feature-carousel-detail {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.main--content {
		margin-top: 0 !important;
	}

	.button {
		@include themed {
			background: transparent !important;
			border: 2px solid #333 !important;
		}
	}

	.js-masonry {
		height: auto !important;
	}

	.js-masonry li {
		position: static !important;
		display: inline-block;
		width: 30%;
		vertical-align: top;
	}

	.contact-list a {
		display: none;
	}

	.site-footer-copyright {
		padding-top: 0 !important;
	}

	table,
	tr,
	td {
		border-color: $gray3 !important;
	}

	thead tr {
		border-bottom: 1px solid $gray3;
	}

	th:not(:last-child),
	td:not(:last-child) {
		border-right: 1px solid $gray3;
	}
}

.receipt-overview {
	@extend .clearfix;

	.col {
		float: left;
		margin-bottom: 2em;
		width: 50%;
	}
}