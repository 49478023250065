// Type
// ====

$sans: open-sans;

$fonts: (
  open-sans: (
    stack: ("Open Sans", "Helvetica Neue", helvetica, arial, serif),
    weights: (light: 300, normal: 400, semibold: 600, bold: 700),
    styles: (normal: normal italic, semibold: normal, bold: normal italic)
  )
);
