// Themes
// ======

$themes: () !default;

@mixin themed() {
  @content;

  $_prevTheme: $theme; $_prevThemeLight: $theme-light; $_prevThemeDark: $theme-dark;
  @each $t, $palette in $themes {
    $theme: map-get($palette, base) !global;
    $theme-light: map-get($palette, light) !global; 
    $theme-dark: map-get($palette, dark) !global;
    body.#{$t} &,
    html.#{$t} & {
      @content;

      // Modules can override global theme
      .#{$t} & {
        @content;
      }
    }


  }
  $theme: $_prevTheme; $theme-light: $_prevThemeLight; $theme-dark: $_prevThemeDark;
}
