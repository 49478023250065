/// <reference path="../aisc.scss" />

// Themes
// ======

$themes: (
  violet: (base: #7834a1, light: #9440c9, dark: #580d84),
  sapphire: (base: #2336ae, light: #3148d4, dark: #142283),
  pumpkin: (base: #f26722, light: #ff7e3d, dark: #c44d13),
  teal: (base: #056e74, light: #119099, dark: #054f52),
  steel: (base: #2378ae, light: #2d96d7, dark: #1a6491),
  iris: (base: #3d378d, light: #5a51c6, dark: #191174),
  steelblue:(base: #235dae, light: #4885db, dark: #1a4684),
);

$theme: $primary !default;
$theme-dark: $primary !default;
$theme-light: $primary !default;

$overlayText: #fff;

@mixin color-grad {
	@include themed {
		background-color: $theme;
		@include background-image(linear-gradient(top, $theme 0, $theme-dark 100%));
	}
}

// Color classes
// -------------
//
// For demo purposes only.
//

@each $t, $palette in $themes {
  .color-#{$t} { background-color: map-get($palette, base) !important; }
  .color-#{$t}-light { background-color: map-get($palette, light) !important; }
  .color-#{$t}-dark { background-color: map-get($palette, dark) !important; }
}
