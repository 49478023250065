// Layouts
// =======

%inner {
	@extend .clearfix;
	@extend %unstyled-list;

	margin-left: auto;
	margin-right: auto;
	max-width: $layout-width;
	padding: 0 20px 0 0;

	@include query(bravo) {
		padding-right: 3.5rem;
	}

	& & {
		margin-left: -20px;
		margin-right: -20px;

		@include query(bravo) {
			margin-left: -3.5rem;
			margin-right: -3.5rem;
		}
	}
}

.inner {
	@extend %inner;
}

%unit {
	float: left;
	padding-bottom: 20px;
	/*padding-bottom: 3rem;*/
	padding-left: 20px;
	width: 100%;

	@include query(bravo) {
		padding-bottom: 20px;
		padding-left: 3.5rem;
	}
}

.unit {
	@extend %unit;
}

/*.unit .unit {
	padding-bottom: 0;
}*/

.unit + .unit {
	margin-top: 2em;

	@include query(bravo) {
		margin-top: 0;
	}
}

.mod.unit + .mod.unit {
	margin-top: -1px;
}

.float-right {
	float: right;
}

.float-left {
	float: left;
}

%row {
	padding-bottom: 2em;
	padding-top: 2em;

	@include query(bravo) {
		padding-bottom: 3em;
		padding-top: 3em;
	}
}

.row {
	@extend %row;

	border-top: 1px solid $border-color;
}

.gray-bg {
	@extend %row;

	background: $gray1;
}

.main--content {
	margin-top: 20px;

	@include query(bravo) {
		margin-top: 3.5rem;
	}
}