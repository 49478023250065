// Gallery
// =======

.gallery-block,
.masonry-block {
	background: #fff;
	box-shadow: 0 1px 3px 1px rgba(0,0,0,0.08);
}

.sidebar .gallery-block,
.sidebar .masonry-block {
	box-shadow: none;
}

.gallery-block,
.gallery-image,
.masonry-block {
	display: block;

	&-wrap {
		background: $gray6;
		overflow: hidden;

		img {
			display: block;
			opacity: 0.75;
			-moz-transition: all 200ms ease-out;
			-o-transition: all 200ms ease-out;
			-webkit-transition: all 200ms ease-out;
			transition: all 200ms ease-out;
			width: 100%;
		}
	}

	&-caption {
		color: $primary;
		padding: 1.25em;

		&-location {
			display: block;
			font-size: size(small);
			margin: 0.25em 0;
			text-transform: uppercase;
		}

		&-location .fa {
			margin-right: 0.25em;

			@include themed {
				color: $theme;
			}
		}

		> :last-child {
			margin-bottom: 0;
		}
	}

	a {
		text-decoration: none;
	}

	& a:hover,
	& a:focus {

		@include themed {
			.gallery-image-caption-title {
				color: $theme-dark;
			}
		}

		.gallery-image-wrap img {
			opacity: 1;
		}
	}
}

.media--Gallery .gallery-block a,
.media--Gallery .masonry-block a {
	-moz-transition: all 200ms ease-out;
	-o-transition: all 200ms ease-out;
	-webkit-transition: all 200ms ease-out;
	transition: all 200ms ease-out;

	&:hover,
	&:focus {
		box-shadow: 0 2px 14px 1px rgba(0,0,0,0.12);
	}
}

.media--Gallery-wrapper {
	background: $gray1;
	border-top: 1px solid $border-color;
	padding-bottom: 1em;
	padding-top: 2em;

	@include query(alpha) {
		padding-bottom: 2em;
		padding-top: 4em;
	}
}

.media--Gallery-title {
	margin: 0 0 1em 0;
}

.linklist-item {
	border-bottom: 1px solid $gray2;
	padding: 0.5em 0;

	&:last-child {
		border-bottom: none;
	}
}

.gallery-image-caption .year {
	margin-top: 0;
}

