// Site header
// ===========

.logo {
	@extend %unstyled-link;
	display: inline-block;
	vertical-align: middle;
}

.business--unit .inner {
	padding: 1em 20px;

	@include query(bravo) {
		padding-bottom: 0.5em;
		padding-top: 0.5em;
	}

	@include query(charlie) {
		padding-top: 2.5em;
	}
}

.site-header {
	@include query(bravo) {
		background: -moz-linear-gradient(top, rgba(255,255,255,0.96) 0, rgba(255,255,255,0.88) 100%);
		background: -webkit-linear-gradient(top, rgba(255,255,255,0.96) 0, rgba(255,255,255,0.88) 100%);
		background: linear-gradient(to bottom, rgba(255,255,255,0.96) 0, rgba(255,255,255,0.88) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5ffffff', endColorstr='#e0ffffff',GradientType=0 );
		min-height: 47px;
		position: absolute;
		width: 100%;
		z-index: 100;
	}
}