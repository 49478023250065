// Clearfix
// ========

/**
 * Micro clearfix, as per: css-101.org/articles/clearfix/latest-new-clearfix-so-far.php
 */
.clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

/* MICRO CLEARFIX HACK */
.cf:before, .cf:after {content:"";display:table} /* For modern browsers */
.cf:after {clear:both}
.cf {zoom:1} /* For IE 6/7 (trigger hasLayout) */