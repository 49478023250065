// Colors
// ======

$light: #fafafa;
$gray: #acacac;
$primary: #6e6e6e;
$darkgray: #595959;
$dark: #000;

$gray1: $light;
$gray2: #e8e8e8;
$gray3: $gray;
$gray4: $primary;
$gray5: $darkgray;
$gray6: $dark;

$border-color: #dcdcdc;

$color-success: #50a96d;
$color-error: #c53232;
$color-info: #ecf4f9;

// Grays
// -------

$grays: (
	gray1: $gray1,
	gray2: $gray2,
	gray3: $gray3,
	gray4: $gray4,
	gray5: $gray5,
	gray6: $gray6
);

@each $name, $color in $grays {
	.#{$name} { background-color: $color !important; }
}
