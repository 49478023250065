// Modules
// =======

$mod-border: 1px solid $border-color;

.modules {
	border-top: $mod-border;
}

.modules > .inner {
	padding-right: 0;
	position: relative;
}

.modules-header {
	padding: 2em 20px 0 20px;

	@include query(bravo) {
		left: 0;
		padding: 0;
		position: absolute;
		text-align: center;
		top: 0;
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		width: 100%;
	}
}

.mod {
	border-top: $mod-border;
	margin-top: -1px;
	padding: 3rem 20px;

	&:first-child {
		border-top: none;
	}

	@include query(bravo) {
		border-bottom: none;
		border-right: $mod-border;
		padding: 2rem 3.5rem;

		&:last-child {
			border-right: none;
		}

		//some weird hacky stuff that doesn't work very well
		&.size1of3.bravo-size1of2:nth-child(even):not(:last-child) {
			border-bottom: $mod-border;
			border-right: none;
		}
	}

	@include query(charlie) {
		//some weird hacky stuff that doesn't work very well
		&.size1of3.bravo-size1of2:nth-child(even) {
			border-bottom: none;
		}

		&.size1of3.bravo-size1of2:nth-child(even):not(:last-child) {
			border-bottom: none;
			border-right: $mod-border;
		}
	}
}

.modules .mod {
	@include query(bravo) {
		padding-bottom: 5rem;
		padding-top: 5rem;
	}
}

.mod-header {
	@extend %title;
}

.mod-alt {
	@include color-grad;
	
	border-radius: 3px;
	box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.3);
	color: #fff;
}

.sidebar .mod {
	border: 1px solid $border-color;
	float: none;
	margin-bottom: 20px;
	margin-top: 0;
	padding: 0;
}

.sidebar .mod-header {
	@extend %sidekick;

	background: $gray1;
	border-bottom: 1px solid $border-color;
	margin: 0;
	padding: 1rem 20px;
}

.sidebar .mod-bd {
	overflow-wrap: break-word;
	padding: 1rem 20px;
	word-wrap: break-word;
}

.mod-link_list {
	li + li {
		border-top: 1px solid $border-color;
	}

	a {
		display: block;
		padding: 0.5em 0;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}
