// Lists
// =====

%list, ol, ul {
    margin-left: 0;
}

%unstyled-list {
    list-style-type: none;
    padding-left: 0;
}

.vlist {
    @extend %unstyled-list;

    > li {
        display: block;
    }
}

.vlist-even {
    background: $light;
}

.hlist {
    @extend .clearfix;
    @extend %unstyled-list;

    > li {
        float: left;
    }
}

.ilist {
    @extend %unstyled-list;

    > li {
        display: inline-block;
        vertical-align: top;
    }
}

dl {
    margin: 0 0 1em 0;
}

dt {
    @extend %sidekick;
    margin-bottom: 0.25em;
    margin: 1em 0 0.25em 0;
}

dd {
    margin-left: 0;
}

dl + dl {
    border-top: 1px solid $border-color;
}

.spec-list {
    @extend %unstyled-list;
    color: $gray4;
    margin-bottom: size(xxlarge);

    > dt {
        @extend %snack;
        clear: left;
        color: $gray4;
        float: left;
        font-size: size(small);
        margin-top: 0.325em;
        margin-right: 0.75em;
    }

    > dd {
        margin-bottom: 0.5em;
    }
}

// Block grids
// -----------

@mixin block-grid($max, $prefix: '', $postfix: '') {
    %#{$prefix}block-grid#{$postfix} {
        list-style-type: none;
        padding-left: 0;
        // margin-left: -1*size(spacing); 
        margin-left: -1*size(small);

        > * {
            display: inline-block;
            // padding-top: size(spacing);
            padding-top: size(small);
            // padding-left: size(spacing);
            padding-left: size(small);
            vertical-align: top;
        }

        &.js-masonry {
            margin-top: 0;
        }
    }

    @for $num from 1 through $max {
        .#{$prefix}block-grid-#{$num}#{$postfix} {
            @extend %#{$prefix}block-grid#{$postfix};

            > * {
                width: percentage(1/$num);

                @if $num > 1 {
                    @for $span from 2 through $num {
                        &.span-#{$span} {
                            width: $span*percentage(1/$num);
                        }
                    }
                }
            }

            &:not(.js-masonry) {
                @for $first from 1 through $num {
                    > :nth-child(#{$first}) {
                        padding-top: 0;
                    }
                }
            }
        }
    }
}

@include block-grid($columns);

@each $name, $query in $breakpoints {
    @include query($name) {
        @include block-grid($columns, $prefix: $name + '-');
    }
}
