.social-links {
	@extend %unstyled-list;
	margin: 0;
}

dl .social-links {
	font-size: size(small);
}

.social-links li {
	display: inline-block;
	margin: 0 0.275em;
	vertical-align: middle;

	@include query(bravo) {
		margin-left: 0.5em;
		margin-right: 0.5em;
	}
}

.social-links a {
	background: #fff;
	border: 1px solid $gray4;
	border-radius: 100%;
	color: $gray4 !important;
	display: block;
	font-size: size(big);
	height: 2em;
	line-height: 2;
	text-align: center;
	text-decoration: none;
	-moz-transition: all 200ms ease-out;
	-o-transition: all 200ms ease-out;
	-webkit-transition: all 200ms ease-out;
	transition: all 200ms ease-out;
	width: 2em;
}

.social-links a:hover {
	border-color: $gray6;
	color: $gray6 !important;
}

