// Section nav
// ===========

.section-nav {
    border: 1px solid $border-color;

    &.close {//overriding bootstrap hover styles
        &:hover {
            opacity: 1;
            color: initial;
            text-decoration: initial;
            cursor: initial;
        }
    }

    @include query(charlie) {
        margin-bottom: 20px;
    }

    &-header {
        background: $light;
        cursor: pointer;
        margin: 0;
        padding: 1rem;

        @include query(bravo) {
            cursor: auto;
        }
    }

    .js &-menu {
        display: none;

        @include query(bravo) {
            display: block !important;
        }
    }

    &-menu {
        @extend .vlist;

        > li {
            > a {
                border-top: 1px solid $border-color;
                display: block;
                padding: 0.8em;
                text-decoration: none;

                &.active {
                    @include themed {
                        background-color: $theme;
                    }

                    color: #fff;
                }

                @include attention {
                    background-color: $gray1;
                    text-decoration: underline;
                }
            }
        }
    }
}

.no-nav {
    display: none;

    @include query(bravo) {
        display: block;
    }

    @include query(charlie) {
        display: none;
    }
}
