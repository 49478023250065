// Tables
// ====

table {
	border: 1px solid $border-color;
}

thead {
	@include themed {
		background-color: $theme;
	}
}

tbody tr {
	border-bottom: 1px solid $border-color;
}

tbody tr:nth-child(even) {
	background: $gray1;
}

td {
	padding: 0.75em 1em;
}

th {
	@extend %shrimp;

	color: #fff;
	padding: 1em;
	text-align: left;
	-moz-transition: background 200ms ease-out;
	-o-transition: background 200ms ease-out;
	-webkit-transition: background 200ms ease-out;
	transition: background 200ms ease-out;
}

th a {
	text-decoration: underline;
	-moz-transition: color 200ms ease-out;
	-o-transition: color 200ms ease-out;
	-webkit-transition: color 200ms ease-out;
	transition: color 200ms ease-out;

	@include themed {
		color: #fff;
	}
}

th a:hover,
th a:focus {
	@include themed {
		color: mix($theme, #fff, 25%);
	}
}

@include query(charlie, true) {
	.responsive-table {
		position: relative;
		margin-bottom: 1.5em;
		max-width: 100%;
		overflow: auto;
		-webkit-overflow-scrolling: touch;

		table {
			margin-bottom: 0;
		}

		.table-pad {
			padding-left: 1em;
		}

		td {
			min-width: 200px;
		}
	}

	.ie9 .responsive-table .overlay {
		filter: none !important;
	}
}

th.sort-header {
	cursor: pointer;
	position: relative;
	padding-right: 1.875em;
	text-decoration: underline;
    &:nth-child(3){
        width:100px;
    }
}

th.sort-header:hover {
	@include themed {
		background-color: $theme-light;
	}
}

th.sort-header a {
	display: block;
	margin: -1em -1.875em -1em -1em;
	padding: 1em 1.875em 1em 1em;
}

th.sort-header a:hover {
	@include themed {
		color: #fff;
	}
}

th.sort-header:after {
	border-width: 0 4px 4px;
	border-style: solid;
	border-color: #fff transparent;
	content: '';
	margin-top: -2px;
	position: absolute;
	right: 0.75em;
	top: 50%;
	visibility: hidden;
}

th.sort-header:hover:after {
	visibility: visible;
}

th.sort-up:after,
th.sort-down:after,
th.sort-down:hover:after {
	opacity: 0.65;
	visibility: visible;
}

th.sort-up:after {
	border-bottom: none;
	border-width: 4px 4px 0;
}

.table-grid td:nth-child(even) {
	background: rgba(#000, .02);
}

.table-grid th:nth-child(even) {
	background: rgba(#000, .07);
}

.table-grid td {
	min-width: 0;
}