// Portal Links Home
// ========

.portal-link-first, .portal-link-mid, .portal-link-last {
	padding: .5em;
	-webkit-transition: background .2s, color .2s;
	-o-transition: background .2s, color .2s;
	transition: background .2s, color .2s;
	@include themed {border: 1px solid $theme;}
}

.portal-link-first:hover, .portal-link-mid:hover, .portal-link-last:hover {

	@include themed {background: $theme;}
}

.portal-link-first:hover, .portal-link-mid:hover, .portal-link-last:hover {
	color: #FFFFFF;
	@include themed{

		&:hover a {
			color:#FFF;
		}
		&:hover p.fa {
			color: #FFF;
		}
	}
}

.portal-link-first, .portal-link-mid {
	
}

.portal-link-first *, .portal-link-mid *, .portal-link-last * {
	display: block;
}


.portal-link-first p.fa, .portal-link-mid p.fa, .portal-link-last p.fa {
	font-size: 48px;
	padding-bottom: 5px;
	@include themed {color:$theme;}
	


}

