.certified-map {
	border: 1px solid $border-color;
	height: 200px;
	margin-bottom: 2em;

	@include query(alpha) {
		height: 300px;
	}

	@include query(bravo) {
		height: 400px;
	}
}

.map-info-window {
	width: 220px;
}

.map-items {
    margin: 0em !important;
}