.heropage-section {
    border-top: 1px solid $border-color;
    @extend .clearfix;

    &.row,
    .row { //overriding negative margins from previous vendor which caused page to scroll horizontally
        margin-left: auto;
        margin-right: auto;
    }

    .displayoptionsrowblock + .displayoptionsrowblock {
        border-top: 1px solid $border-color;
    }

    .inner,
    &-block-container {
        max-width: 100%;
        width: 1200px;
        margin-left: auto;
        margin-right: auto;
    }

    .unit { //this class is getting applied through a custom c# class from previous vendor
        &:empty {
            display: none; //accounting for previous vendor's addition of empty html elements despite blocks having no content
        }

        &.displayoptionsrowblock {
            padding-left: 0;
            padding-bottom: 0;
        }

        + .unit {
            margin-top: 0;
        }
    }

    .mod {
        border-right: none;
    }

    &-slider {
        .unit {
            height: auto;
            padding-bottom: 0;
        }

        .customIFrame {
            &,
            iframe {
                height: auto;
            }
        }

        .feature-carousel {
            margin: 0 20px 20px;
        }
    }
}

@media (min-width: 75em) {
    .heropage-section {
        .mod {
            border-right: 1px solid $border-color;

            &:last-child {
                border-right: none;
            }
        }

        &-block-container {
            padding: 0 20px 0 0;
        }
    }
}

@media (min-width: 52em) {
    .heropage-section {
        &-slider {
            display: flex;

            .customIFrame {
                &,
                iframe {
                    height: 100%;
                }
            }

            .feature-carousel {
                margin: 0 20px;
            }
        }
    }
}