// Palette
// =======

.palette {
  @extend .ilist;

  font-size: 0;
  text-align: center;

  > li {
    @extend .clearfix;

    font-size: 14px;
    padding: 1em 2.5%;
    text-align: center;
    width: percentage(1/3);

    @include query(alpha) {
      width: percentage(1/6);
    }
  }

  &-color, &-color-light, &-color-dark {
    background-color: hsb(0, 0, 0);
    display: block;
    border: 3px solid hsb(0, 0, 100);
  }

  &-color {
    padding-bottom: 100%;
    position: relative;
    width: 100%;
  }

  &-color-light, &-color-dark {
    float: left;
    padding-bottom: 50%;
    width: 50%;
  }

  &.grays &-color {
    padding-bottom: 50%;
  }

  .color {
    color: #fff;
    position: absolute;
    bottom: 0.5em;
    left: 0;
    right: 0;
  }
}
