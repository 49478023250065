// Portal Links
// ========

.portalLink h4 {
    color: #ffffff;
    margin-top: 20%;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    letter-spacing: .1em;
    -webkit-text-shadow: 0 1px 2px #000000;
    -moz-text-shadow: 0 1px 2px #000000;
    -ms-text-shadow: 0 1px 2px #000000;
    -o-text-shadow: 0 1px 2px #000000;
    text-shadow: 0 1px 2px #000000;
}

.portalLink a:hover {
    color: #FFFFFF;
}


.portalLink--backsplash h4 {
    color: #ffffff;
    margin-top: 20%;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    letter-spacing: .1em;
    -webkit-text-shadow: 0 1px 2px #000000;
    -moz-text-shadow: 0 1px 2px #000000;
    -ms-text-shadow: 0 1px 2px #000000;
    -o-text-shadow: 0 1px 2px #000000;
    text-shadow: 0 1px 2px #000000;
}

.portalLink--backsplash a:hover {
    color: #FFFFFF;
}

.portal-links {
  margin-bottom: 2em;
}

.portal-link {
  &, &-backrop, &-backdrop-color {
    @include transition(all 125ms ease-out);
  }

  @extend %unstyled-link;

  display: block;
  height: 10em;
  position: relative;
  text-align: center;
  z-index: 2;

  @include transform-origin(50% 50%);
  @include attention {
    box-shadow: 0px 15px 12px 0px rgba(#000, 0.22), 0px 19px 38px 0px rgba(#000, 0.30);
    z-index: 20;

    @include transform(scale(1.1));
  }

  &::before, &-content {
    display: inline-block;
    vertical-align: middle;
  }

  &::before {
    content: '';
    height: 100%;
  }

  &-content {
    padding: 1em;
  }

  &-backdrop {
    &, &-color {
      @include themed{ background-color: darken($theme-dark, 10%); }
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    border-color: rgba(255,255,255,0.16098);
    border-style: solid;
    border-width: 0;
    overflow: hidden;
    z-index: -1;

    &-color {
      opacity: 0.8;
    }
  }

  &-title {
    @extend %uppercase;

    margin: 0;

    @include font($sans, $size: large, $weight: semibold);
  }

  &-description {
    margin-top: 1em;
  }

  // Backgrounds
  &.has-backdrop {
    text-shadow: 0 1px 2px rgba(#000, 0.5);

    @include themed {
      color: #fff;
    }
    @include attention {
      @include themed {
        color: #fff;
      }

      .portal-link-backdrop {
        border-width: 6px;

        &-color {
          opacity: 0.875;
        }
      }
    }
  }

}

.portalLinks--wrapper {
  background: rgb(237,241,245);
  border-top: 1px solid rgb(215,220,226);
  // margin-bottom: 1em;

  .portal-links {
    margin-top: 52px;
    margin-bottom: 32px;
  }
}

// .homepage--portalLinks .portal-link-title {
//   font-size: 1em;
// }
