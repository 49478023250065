// NSBA Rep
// ======

.nsba-rep {
	background: #FFFFFF;
	border: none;
}


.nbiSearchWrapper{
    background: #edf1f5;
    border-top: 1px solid #d7dce2;
    padding: 3em 0 0 0;

}

.nbiSearchWrapper h2 {
  @include themed {
    color: $theme-dark;
  }
}

.nsbaRepWrapper {
  padding-top: 20px;
}

.nsbaContactDropdown {
  border-right:none;
}

.nsbaProfileList {
  margin:2em 0 0 0;
}


.ctaFindFabricator .call-to-action {
  @include themed {
    background: $theme;
    color: $overlayText;
  }
}
