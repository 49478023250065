/// <reference path="../aisc.scss" />

// Buttons
// =======

a.button,
.button {
    background: none;
    border-radius: 3px;
    color: #fff !important;
    cursor: pointer;
    display: inline-block;
    letter-spacing: 0.07em;
    padding: 0.5em 1.375em;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    -moz-transition: background 200ms ease-out;
    -o-transition: background 200ms ease-out;
    -webkit-transition: background 200ms ease-out;
    transition: background 200ms ease-out;

    @include font($sans, $weight: semibold);

    @include themed {
        background-color: $theme;
        border: 2px solid $theme;
    }

    &:hover,
    &:focus {
        text-decoration: none;

        @include themed {
            background-color: $theme-dark;
            border-color: $theme-dark;
        }
    }

    + .button {
        margin-top: 1em;
    }
}


.mod-alt a.button,
.mod-alt .button {
    @include themed {
        background-color: #fff !important;
        border-color: #fff !important;
        color: $theme !important;

        &:hover,
        &:focus {
            background: transparent !important;
            color: #fff !important;
        }
    }
}

a.button-secondary,
.button-secondary {
    @include themed {
        background: none;
        border-color: $theme;
        color: $theme !important;
    }

    &-accordion {
        margin-bottom: 10px;
    }

    &:hover,
    &:focus {
        @include themed {
            background: $theme-dark;
            border-color: $theme-dark;
            color: #fff !important;
        }
    }
}

.accordion-header-right {
    float: right !important;
    vertical-align: middle;
}

.accordion-header-left {
    float: left !important;
    margin-right: 5px !important;
    vertical-align: middle;
}

a.button-arrow,
.button-arrow {
    background: $gray2 !important;
    border: none !important;
    color: $gray5 !important;
    min-width: 10em;
    padding: 0.75em 3.75em 0.75em 2em;
    position: relative;

    &-accordion {
        margin: 0px 0px 0px 0px;
    }

    &:hover,
    &:focus {
        @include themed {
            background: $theme-dark !important;
            color: #fff !important;
        }
    }
}

a.button-arrow .fa,
.button-arrow .fa {
    border-radius: 0 3px 3px 0;
    color: #fff;
    float: right;
    display: block;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 1.75em;

    @include themed {
        background: $theme;
    }
}

a.button-arrow:hover .fa,
.button-arrow:hover .fa {
    @include themed {
        background: $theme-dark;
    }
}

.button-arrow .fa:before {
    font-size: size(big);
    left: 0;
    position: absolute;
    top: 50%;
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
}

a.button-mini,
.button-mini {
    font-size: size(small);
}

a.button-fake,
.button-fake {
    cursor: default;
}

.section-divider .button-primary,
.section-divider .button-fake {
    position: relative;
    top: -20px;
    margin-left: 10%;
    margin-right: 10%;
    display: inline-block;
}
