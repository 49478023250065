// Feature Carousel
// =======

.feature-carousel {
	@extend .clearfix;

	background: #fff;
	border-radius: 3px;
	box-shadow: 0 1px 3px 1px rgba(0,0,0,0.08);
	margin: 20px 20px 0 20px;

	@include query(charlie) {
		margin: 0;
		overflow: hidden;
		text-align: right;
	}
}

.inner .feature-carousel {
	/*margin-right: -20px;

	@include query(alpha) {
		margin-right: 0;
	}*/
}

.unit .feature-carousel {
	/*margin-left: -20px;

	@include query(alpha) {
		margin-left: 0;
	}*/
}

.feature-carousel-detail {
	padding: 2rem 20px;

	@include query(charlie) {
		float: left;
		padding: 3.5rem 3.5rem 2rem 5rem;
		width: 60%;
	}
}

.feature-carousel-title {
	@extend %title;
	margin-top: 0;
}

.feature-carousel-img.img-small {
	display: block;

	@include query(charlie) {
		display: none;
	}
}

.feature-carousel-img.img-large {
	display: none;
	float: right;
	width: 40%;

	@include query(charlie) {
		display: block;
	}
}

.feature-carousel .slick-prev,
.feature-carousel .slick-next {
	@include query(charlie) {
		background: #fff;
		bottom: auto;
		box-shadow: 0 1px 3px 1px rgba(0,0,0,0.08);
	}
}

.feature-carousel .slick-prev {
	@include query(charlie) {
		border-radius: 0 3px 3px 0 !important;
		left: 0;
	}
}

.feature-carousel .slick-next {

	@include query(charlie) {
		border-radius: 3px 0 0 3px !important;
		right: 0;
	}
}
