// Visibility
// ==========

@mixin hidden {
  display: none !important;
}

.hidden, .aspNetHidden {
  @include hidden;
}

.visible {
  display: block !important;
}

.visible-inline {
  display: inline !important;
}

.inline-and-children {
	display: inline;

	& > * {
		display: inline;
	}
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

// Queries
// -------

@each $breakpoint, $query in $breakpoints {
  .visible-#{$breakpoint},
  .visible-inline-#{$breakpoint} {
    &, &-up { @extend .hidden; }
  }

  @include query($breakpoint) {
    @each $b, $query in $breakpoints {
      @if $b != $breakpoint {
        .visible-#{$b},
        .visible-inline-#{$b} {
          display: none !important;
        }
        .hidden-#{$b} {
          display: block !important;
        }
      }
    }

    .visible-#{$breakpoint} {
      &, &-up { display: block !important; }
    }
    .visible-inline-#{$breakpoint} {
      &, &-up { display: inline !important; }
    }
    .hidden-#{$breakpoint} {
      &, &-up { @include hidden; }
    }
  }
}


@include query(alpha) {
  .aa-narrowest,
  .narrowest,
  .aa-bravo,
  .bravo,
  .aa-charlie,
  .charlie {
    display: none;
  }
  .aa-alpha,
  .alpha {
    display: block;
  }

}  /*=================== END ALPHA QUERY =================== */

@include query(bravo) {
  .aa-narrowest,
  .narrowest,
  .aa-alpha,
  .alpha,
  .aa-charlie,
  .charlie {
    display: none;
  }
  .aa-bravo,
  .bravo {
    display: block;
  }

} /* =================== END BRAVO QUERY =================== */

@include query(charlie) {

  .aa-narrowest,
  .narrowest,
  .aa-small,
  .alpha,
  .aa-bravo,
  .bravo {
    display: none;
  }
  .aa-charlie,
  .charlie {
    display: block;
  }

} /* =================== END CHARLIE QUERY =================== */
