// Breadcrumbs
// =============

.breadcrumb {
	@extend %label;
}

.breadcrumb .hlist {
	padding: 0;
}

.breadcrumb ul {
	padding: 1em;
}

.breadcrumb .hlist li {
	margin-right: .5em;
}

.breadcrumb .separator {
	margin-right: .5em;
}

.breadcrumb li:first-child {
	font-weight: 700;
}

.breadcrumb li:first-child .separator {
	display: none;
}

section.breadcrumbs {
	background: $gray2;
}

section.breadcrumbs .unit {
	padding-bottom: 1rem;
	padding-top: 1rem;
}

.breadcrumb a {
	text-decoration: none;
}