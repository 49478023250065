.content--list__section ul {
    @extend %unstyled-list;
    border-top: 1px solid $border-color;
    margin: 0;
}

.content--list__section li {
    border-bottom: 1px solid $border-color;
    padding: 1.5em 0;
    position: relative;

    &.search-listing-item {
        p:last-child {
            &:after {
                content: '...';
            }
        }
    }
}

.content--list__section li > *:first-child {
    margin-top: 0;
}

.content--list__section li > *:last-child {
    margin-bottom: 0;
}

.content--list__section .date {
    font-size: size(small);
    text-transform: uppercase;
}
