// Top Navigation
// =======


#off--canvas-toggle {
	@extend %sidekick;
}

@include query(bravoMax) {
	/* --- Off Canvas Menu --- */
	#off--canvas-toggle {
		color: $gray5;
		font-size: 1em;
		height: 38px;
		padding: 0;
		text-decoration: none;
		white-space: nowrap;

		&:hover,
		&:focus {
			color: $gray6;
		}
	}

	#off--canvas-toggle .fa {
		height: 38px;
		padding: 1.2em 0.5em 1em 1em;
	}

	.show-nav #off--canvas-toggle .fa {
		background: $gray6;
		color: #fff;
		margin-right: 0.25em;
		padding-right: 1em;

		&:before {
			content: '\f054';
		}
	}

	.show-nav #off--canvas-toggle:hover .fa {
		background: $gray5;
	}

	.js #off--canvas-wrapper {
		position: relative;
		overflow: hidden;
		width: 100%;
	}

	.js #off--canvas-menu {
		width: 85%;
		height: 100%;
		position: absolute;
		top: 0;
		/*left: -85%;*/
		box-shadow: inset -5px 0 16px rgba(0,0,0,0.15);
		/*right: -90%;*/
		z-index: -1;
	}

	.show-nav #off--canvas-menu {
		z-index: 2;
	}

	.js #off--canvas-body {
		background: #ffffff;
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 1;
	}

	.js #off--canvas-body,
	.js #off--canvas-menu {
		-moz-transition: 300ms ease-out all;
		-ms-transition: 300ms ease-out all;
		-o-transition: 300ms ease-out all;
		-webkit-transition: 300ms ease-out all;
		transition: 300ms ease-out all;
	}

	.js #off--canvas-body,
	.js.csstransforms #off--canvas-wrapper.show-nav #off--canvas-menu,
	.js.csstransforms3d #off--canvas-wrapper.show-nav #off--canvas-menu {
		-moz-transform: translateX(0);
		-ms-transform: translateX(0);
		-o-transform: translateX(0);
		-webkit-transform: translateX(0);
		transform: translateX(0);
		-moz-transform: translateZ(0) translateX(0);
		-ms-transform: translateZ(0) translateX(0);
		-o-transform: translateZ(0) translateX(0);
		-webkit-transform: translateZ(0) translateX(0);
		transform: translateZ(0) translateX(0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		-moz-backface-visibility: hidden;
		-ms-backface-visibility: hidden;
		-o-backface-visibility: hidden;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
	}

	.js.csstransforms #off--canvas-wrapper.show-nav #off--canvas-body,
	.js.csstransforms3d #off--canvas-wrapper.show-nav #off--canvas-body {
		-moz-transform: translateX(85%);
		-ms-transform: translateX(85%);
		-o-transform: translateX(85%);
		-webkit-transform: translateX(85%);
		transform: translateX(85%);
		-moz-transform: translateZ(0) translateX(85%);
		-ms-transform: translateZ(0) translateX(85%);
		-o-transform: translateZ(0) translateX(85%);
		-webkit-transform: translateZ(0) translateX(85%);
		transform: translateZ(0) translateX(85%);
		-moz-transform: translate3d(85%, 0, 0);
		-ms-transform: translate3d(85%, 0, 0);
		-o-transform: translate3d(85%, 0, 0);
		-webkit-transform: translate3d(85%, 0, 0);
		transform: translate3d(85%, 0, 0);
	}

	.js #off--canvas-menu {
		-moz-transform: translateX(-85%);
		-ms-transform: translateX(-85%);
		-o-transform: translateX(-85%);
		-webkit-transform: translateX(-85%);
		transform: translateX(-85%);
		-moz-transform: translateZ(0) translateX(-85%);
		-ms-transform: translateZ(0) translateX(-85%);
		-o-transform: translateZ(0) translateX(-85%);
		-webkit-transform: translateZ(0) translateX(-85%);
		transform: translateZ(0) translateX(-85%);
		-moz-transform: translate3d(-85%, 0, 0);
		-ms-transform: translate3d(-85%, 0, 0);
		-o-transform: translate3d(-85%, 0, 0);
		-webkit-transform: translate3d(-85%, 0, 0);
		transform: translate3d(-85%, 0, 0);
	}

	/*.js #off--canvas-menu {
    width: 90%;
    height: 100%;
    position: absolute;
    top: 0;
    right: -90%;
    background: #CCC;
  }*/
	/*#off--canvas-close {
    background: #AAA;
    float: right;
    padding: 1em;
  }*/
}

@include query(bravo) {
	#off--canvas-toggle {
		display: none;
	}
}


.offCanvasMenu {
	background: $gray1;

	.mod {
		padding: 0;
		margin-bottom: 0;
	}
}

.offCanvasMenu a {
}

#off--canvas-close {
	color: #fff !important;
	display: none;
	float: right;
	padding: 12px 17px 13px !important;
}

.offCanvasClose {
	/*@include themed {background: $theme-dark;}*/
	background: rgba(0,0,0,0.33);
}

@each $t, $palette in $themes {
	.secondary--nav ul li.subhead ul li.#{$t} a:hover {
		color: map-get($palette, light) !important;
	}
}

.secondary--nav ul li.subhead ul li.pumpkin a:hover {
	color: map-get(map-get($themes, pumpkin), base) !important;
}

.secondary--nav ul li.subhead ul li.active,
.secondary--nav ul li.subhead ul li.active a,
.secondary--nav ul li.subhead ul li.active a:hover {
	color: #fff !important;

	@include themed {
		background: $theme-light;
	}
}

.secondary--nav ul li.subhead ul li.active a:hover {
	// background: #FFF;
}

.cart--Search {
	position: relative;
}

.cart--Search .cart {
	position: absolute;
	left: 10px;
	top: 1em;
}

.cart--Search .search {
	width: 100%;
	padding-left: 45px;
	box-sizing: border-box;
}

.cart--Search .search .cb {
	display: block;
}

.search {
	display: block;
	clear: both;
}

.js .search .cb {
	padding: 0.5em 1em 0.5em 0.5em;
	position: relative;
}

.js .search .cb input[type="button"] {
	/*position: absolute;*/
	right: 7px;
	top: 10px;
	border: 1px solid #CCC;
	border-radius: 0;
}

.site--header .inner {
	position: relative;
}

.business--nav {
	position: relative;
	z-index: 1000;

	@include query(alpha) {
		position: static;
	}
}

.business--nav .inner {
	padding: 0;
}


.utility--Navigation .logged-in,
.logged-in .utility--Navigation .logged-out,
.utility--Navigation .dropdown-menu {
	display: none;
}

.utility--Navigation .login-area .drop-arrow,
.utility--Navigation .login-area .link--text {
	display: none;
}

@include query(charlie) {
	.utility--Navigation .login-area.logged-in .link--text  {
		display: inline-block;
		margin-right: 0.25em;
		vertical-align: middle;
	}
    .modern-steel-body .utility--Navigation .login-area.logged-in .link--text {
        margin-right: .4em;
    }
}

.utility--Navigation .link--text-highlight {
    border-radius: 3px;
	color: #fff !important;
    display: inline-block;
    font-size: 0.833em;
	margin-left: 0.25em;
    padding: 0.25em 0.5em;
    text-transform: uppercase;

	@include themed {background: $theme;}
}


.utility--Navigation .cart .link--text-highlight {
	margin-left: 0.75em;
    line-height: 1.4;
}

.logged-in .utility--Navigation .logged-in,
.utility--Navigation .dropdown.open .dropdown-menu {
	display: block;
}

@include query(alpha) {
	.utility--Navigation .dropdown {
		position: relative;
		z-index: 1000;
	}
}

.utility--Navigation .dropdown-menu {
	background: #fff;
	box-shadow: 0 2px 5px rgba(0,0,0,0.16);
	left: 0;
	padding: 1em 0;
	position: absolute;
	width: 100%;
	z-index: 2;

	@include query(alpha) {
		left: auto;
		right: 0;
		width: 18em;
	}
}

.utility--Navigation .dropdown-menu li {
	border: none;
	display: block;
	float: none;
	width: 100%;
}

.utility--Navigation .dropdown-menu a {
	display: block;
	height: auto;
	padding: 0.75em 1.25em;
}

.utility--Navigation .dropdown-menu a:hover,
.utility--Navigation .dropdown-menu a:focus {
	@include themed {background: $theme;}

	color: #fff !important;
}

.utility--Navigation .dropdown-menu a:hover .fa,
.utility--Navigation .dropdown-menu a:focus .fa {
	color: #fff !important;
}

.utility--Navigation .dropdown-menu .fa {
    display: inline-block;
	font-size: size(large);
    margin-right: 0.25em;
    text-align: center;
    vertical-align: middle;
    width: 1.25em;
}

.top--Bar {
	background: $gray1;
	border-bottom: 1px solid $gray2;
	font-size: size(small);
	position: relative;
	z-index: 200;
}

.top--Bar a {
	display: inline-block;
	padding: 1em;
	vertical-align: middle;
}

.top--Bar .mod {
	padding: 0;
	margin-bottom: 0;
}

.site--Links {
	float: left;
}

.utility--Navigation {
	float: right;
}

.utility--Navigation ul {
	@extend .hlist;

	margin: 0;
}

.utility--Navigation li {
	border-left: 1px solid $gray2;

	@include query(charlie) {
		border-left: none;
		border-right: 1px solid $gray2;
	}
}

.utility--Navigation a {
	height: 38px;
	padding: 0.5em 1.25em;
	text-decoration: none;
	-moz-transition: background 200ms ease-out;
	-o-transition: background 200ms ease-out;
	-webkit-transition: background 200ms ease-out;
	transition: background 200ms ease-out;

	 @include query(bravo) {
		 height: 44px;
		 padding: 0.75em 1.25em;
	 }

	 @include query(charlie) {
		 padding: 1em 1.25em;
	 }

	 &:hover,
	 &:focus {
		 background: #fff;
	 }
}

.utility--Navigation .fa {
	color: $gray4 !important;
	font-size: size(xlarge);
	-moz-transition: color 200ms ease-out;
	-o-transition: color 200ms ease-out;
	-webkit-transition: color 200ms ease-out;
	transition: color 200ms ease-out;
	vertical-align: middle;

	@include query(charlie) {
		font-size: size(large);
	}
}
.modern-steel-body {
    .utility--Navigation a {
        display: flex;
        align-items: center;
        text-decoration: none;
    }

    .login-area {
        .link--text-highlight {
            line-height: 1.4;
        }
    }
}
.utility--Navigation a:hover .fa,
.utility--Navigation a:focus .fa {
    color: $gray6 !important;
}

.site--search-wrapper {
	display: none;
	font-size: 16px;
	padding: 0.5em 2em 0.5em 0;

	@include query(charlie) {
		background: #fff;
		box-shadow: inset 1px -2px 6px $gray2;
		display: block !important;
		font-size: 12px;
		padding: 0;
	}
}

.site--search-icon {
	background: none;
	display: block;
	border: none;
	height: 38px;
	padding: 0.5em 1em;

	@include query(charlie) {
		display: none !important;
	}
}

input.site--search-input {
	/*display: none;*/
	border: none;
	width: 100%;

	@include query(charlie) {
		background: none;
		display: block;
		float: left;
		height: 44px;
		padding: 1em;
		width: 16em;
	}
}

.site--search-button {
	border: none;
	background: transparent;
	cursor: pointer;
	font-size: 1.143em;
	position: absolute;
	right: 1em;
	top: 0.75em;

	@include query(charlie) {
		font-size: 1em;
		float: left;
		height: 44px;
		padding: 1em;
		position: static;
	}

}

.site--search-button:hover .fa,
.site--search-button:focus .fa {
	color: $gray6 !important;
}