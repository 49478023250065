// Prjects
// ======

div.project-details {
    margin-top: 3.5rem;
}

div.project-details > div.inner {
    margin-top: 30px;
}

@include query(alpha) {
    .media .img {
        float: left;
        margin-right: 2em;
        max-width: 35%;
    }

    .product--Detail-counts span {
        display: inline-block;
        padding-right: 0.5em;
    }
}

ul.project-details-list > li.header {
    padding-left: 0;
}

ul.project-details-list {
    border-top: 1px solid $border-color;
}

ul.project-details-list > li {
    border-bottom: 1px solid $border-color;
    padding: 0.5em 1em;
}

ul.project-details-list > li:nth-child(even) {
    background: $gray1;
}

ul.project-details-list .section-title {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

// let some accordions in the list!
.project-details-list .accordion {
    .header {
        background: none;
        cursor: pointer;
        padding: 0;
    }

    .header .sprite {
        top: 0;
        right: 0;
    }

    .item,
    .item:last-child {
        border: none;
    }

    .info,
    .more {
        background: transparent;
        border-top: none;
        padding: 1em 0 0 0;
    }
}

.project-details-list-columns {
    display: flex !important;

    > div {
        //display: inline-block !important;
        flex-grow: 1;
        flex-basis: 50%;
    }

    &-subscriptions {
        border-right: 1px solid #dcdcdc;
    }

    &-cancellations {
        padding-left: 0.5em;
    }
}

@media (max-width: 600px) {
    .project-details-list-columns {
        flex-direction: column;

        > div {
            flex-basis: 100%;
        }

        &-subscriptions {
            border-right: none;
            border-bottom: 1px solid #dcdcdc;
        }

        &-cancellations {
            padding-left: 0em;
            padding-top: 0.5em;
        }
    }
}
