// Tabs
// ======

.tab-navigation {
	display: none;

	@include query(bravo) {
		display: block;
		bottom: -1px;
		position: relative;

		> li {
			&:first-child h3 {
				border-left: 1px solid $border-color;
			}

			& h3 {
				border: 1px solid $border-color;
				border-left: none;
				background: $light;
				margin: 0;

				&.selected {
					background: #ffffff;
					border-bottom: 1px solid #ffffff;
				}
			}

			& a {
				display: block;
				padding: 1em;
			}
		}
	}
}

.tab-container {
	> div {
		background: #fff;
		border: 1px solid $border-color;
		display: block !important;
		margin-bottom: 1em;
		padding: 1em 1em 0 1em;

		@include query(bravo) {
			padding: 0 1em;
		}

		.header {
			background: $light;
			border-bottom: 1px solid $border-color;
			margin: -1em -1em 1em;
			padding: 1em;

			@include query(bravo) {
				display: none;
			}
		}

		&.hidden {
			@include query(bravo) {
				display: none !important;
			}
		}
	}
}
