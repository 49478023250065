// Spacing
// =======

p {
	margin-bottom: 1.25em;
}

.whysteelcard--detail p {
	margin-bottom: 0;
}

hr {
	border-top: 0;
	border-style: none;
	border-bottom: 1px solid $border-color;
	margin-bottom: .5em;
}

.margin-left {
	margin-right: 20px;
}

.margin-top {
	padding-top: 20px;
}

.unit.extra-padding {
	padding-top: 3em;
	padding-bottom: 2em;

	@include query(alpha) {
		&-alpha-max {
			padding-top: 0;
			padding-bottom: 20px;
		}
	}

	@include query(bravo) {
		&-bravo-max {
			padding-top: 0;
			padding-bottom: 20px;
		}
	}

	@include query(charlie) {
		&-charlie-max {
			padding-top: 0;
			padding-bottom: 20px;
		}
	}
}

.pageContent {
	@include query(bravo) {
		margin-top: 1.5em;
	}
}


section.pad25 {
	padding-top: 25px;
}
