// Links
// =====

@mixin attention($self: false) {
	@if $self {
		&,
		&:hover,
		&:focus {
			@content;
		}
	}
	@else {
		&:hover,
		&:focus {
			@content;
		}
	}
}

a {
	@include themed {
		color: $theme;

		@include attention {
			color: $theme-dark;
		}
	}

	@include attention {
		color: $theme-dark;
		text-decoration: underline;
	}
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	text-decoration: none;
	@include attention {
		text-decoration: none;
	}
}

%unstyled-link {
	&, &[href^="#"] {
		border-bottom: none;
	}
}
