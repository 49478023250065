// Sections
// ========

.section {
  &-title {
    border-bottom: 1px solid $border-color;
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    margin-top:0;
    text-align: left;
  }

  &-divider {
    border-top: 1px solid $border-color;
    height: 40px;
    margin-top: 20px;
    text-align: center;
    @include query(charlie) {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}


