// Tag cloud
// =========

.tag-cloud {
	margin-top: 1em;
	@extend .ilist;
}

.tag-cloud li {
	margin: 0 0.5em 0.5em 0;
}

.tag {
	background: rgba($gray, 0.1);
	border-radius: 3px;
	display: inline-block;
	padding: 0.5em 0.75em;
	text-decoration: none;
	-moz-transition: all 200ms ease-out;
	-o-transition: all 200ms ease-out;
	-webkit-transition: all 200ms ease-out;
	transition: all 200ms ease-out;
	white-space: nowrap;

	@include attention {
		text-decoration: none;

		@include themed {
			background: $theme-light;
			color: #fff;
		}
	}
}

.tag.selected {
	@include themed {
		background: $theme-light;
		color: #fff;
	}

	padding-right: 1.5em;
	position: relative;

	&:before,
	&:after {
		content: "";
		width: 2px;
		height: 8px;
		margin-top: -4px;
		background: #fff;
		position: absolute;
		right: 0.75em;
		top: 50%;
	}

	&:before {
		transform: rotate(45deg);
	}

	&:after {
		transform: rotate(-45deg);
	}
}

.tag-list {
	> li {
		margin-left: 0;
		margin-bottom: 1em;
		margin-right: 1em;
	}

	.icon-tag {
		padding-top: 0.375em;
	}

	.icon-x {
		padding-top: 0.375em;
	}
}
