/// <reference path="../aisc.scss" />

// Form Styles
// ======

.formBodyHighlight {
	background: $gray1;
	border: 1px solid $border-color;
	border-radius: 3px;
	padding: 20px;

	@include query(bravo) {
		padding: 2rem 3.5rem;
	}
}

.gray-bg .formBodyHighlight {
	background: #fff;
	border: none;
	box-shadow: 0 1px 3px 1px rgba(0,0,0,0.08);
}

.formHeader {
	@extend %title;
}

.formSubHeader {

}

.formColorFoot {
	border-top: 1px solid $border-color;
	padding-top: 20px;
}


// National Bridge Inventory
// ------

.nbi-search-title {
	@extend %title;
}

.nbi-search-map {
	width: 100%;
}

.nbi-search .form {
	margin-bottom: 20px;
}

.nbi-search .form-item {
	padding-bottom: 0;
}


// Steel Availability Search
// ------

.steel-search {
	@extend .mod-alt;

	border-radius: 0;
	margin-top: -2em;
	position: relative;
	z-index: 100;


	@include query(alpha) {
		margin-bottom: 20px;
	}

	@include query(bravo) {
		margin-top: -3em;
	}

	@include query(charlie) {
		border-radius: 3px;
		box-shadow: 0 2px 8px rgba(0,0,0,0.2), inset 0 1px 0 0 rgba(255, 255, 255, 0.3);
		margin: -6em auto 3.5em auto;
		max-width: 760px;
	}
}

.mod-alt .formBodyHighlight {
	background: none;
    border: none;
    border-radius: 0;
	box-shadow: none;
    padding: 0;
}

.steel-search .form {
	margin: 0 -5px;
    padding-right: 5px;

	@include query(charlie) {
		font-size: size(small);
	}
}

.steel-search .form-item, 
.steel-search .form > li {
	padding-bottom: 5px;
	padding-left: 5px;
}

.steel-search .formHeader {
	@extend %sidekick;

	border-bottom: 1px solid rgba(0,0,0,0.24);
	box-shadow: 0 1px 0 0 rgba(255,255,255,0.12);
	color: #fff !important;
	margin: 0;
	padding: 1em 20px;
	text-align: center;
}

.steel-search .formColorFieldArea {
	padding: 10px 10px 5px 10px;

	@include query(alpha) {
		padding: 20px;
	}

	@include query(charlie) {
		padding: 10px 10px 5px 10px;
	}
}

.steel-search %field {
	padding-left: 0.5em;
	padding-right: 1.5em;
}

.lt-ie10 .steel-search .select select {
	background: #fff;
	border-color: #fff;
	color: $gray5;
	padding: 0.5em 0.5em 0.5em 1em;
}

.steel-search .button {
	display: block;
	padding-left: 0.5em;
	padding-right: 0.5em;
	width: 100%;
}

.event-buttons .button {
	margin: 0.25em 0.25em 0.25em 0;
}

.cal-form {
	display: inline-block;
}

.cal-form .button {
	font-size: 1em;
}

.search-refine {
	@extend .clearfix;

	margin-bottom: 2em;
	max-width: 30em;

	@include query(charlie) {
		display: none;
	}
}

.search-refine input {
	float: left;
	margin-right: 0.25em;
	max-width: 75%;
}

.search-refine button {
	border-width: 1px !important;
	font-size: size(medium);
	padding: 0.5em 0.75em;
}

[data-country="CA"] {
	display: none;
}

.cert-comp-search .select-multi select {
	@include query(bravo) {
		height: 192px;
	}
}

.quiz-result {
	margin: 0 auto;
	max-width: 32em;
	text-align: center;
}

.quiz-summary {
	@include form-message;

	.message-icon {
		display: inline-block;
		margin-bottom: 0.75em;
		position: static;
		vertical-align: middle;
	}

	&.quiz-pass {
		border: 3px solid map-get(map-get($themes, steel), base);

		.message-icon:before {
			color: map-get(map-get($themes, steel), base);
			content: "\f058";
		}
	}

	&.quiz-fail {
		border: 3px solid $color-error;

		.message-icon:before {
			color: $color-error;
			content: "\f06a";
		}
	}

	@include query(alpha) {
		padding: 2.5em 2em;
	}

	.button-primary {
		margin-top: 1.25em;
	}

	.button-secondary {
		margin-top: 1.25em;
	}

}


.quiz-pass .quiz-score {
	color: map-get(map-get($themes, steel), base);
}

.quiz-fail .quiz-score {
	color: $color-error;
}

// forgive me
.quiz-form label * {
	margin: 0;
}

.education-search {
    padding-bottom: 20px;
}