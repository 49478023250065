/// <reference path="../aisc.scss" />


html {
	color: $primary;
	font-size: 100%;
	line-height: strip-units(size(medium));
	-moz-text-size-adjust: none;
	-ms-text-size-adjust: none;
	-webkit-text-size-adjust: none;
	text-size-adjust: none;
	font-kerning: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	@include font($sans, $size: 14px, $weight: normal);
}

code {
	font-size: size(small);
}

blockquote {
	border-left: 1px solid $border-color;
	color: $gray6;
	line-height: 1.75;
	margin: 1rem 0;
	padding-left: 2em;

	@include query(bravo) {
		margin-left: 3.5rem;
		margin-right: 3.5rem;
	}

	> footer {
		background: $gray1;
		display: block;
		margin-top: 1em;
		padding: 0.5em 1em;
	}

	cite {
		color: $gray3;
		font-style: normal;
		text-transform: uppercase;
	}
}

// Headings
// --------
//
// Limit properties to the same in each heading style, and add extra embellishments
// elsewhere.  This way, styles can be interchanged and overriden easily
// regardless of HTML tag.
//

// This hidden class can be used to target all
// headings in an element
%heading {
	line-height: 1.2;
}

%title {
	@extend %heading;
	color: $gray6;
	letter-spacing: 0;
	text-transform: none;

	@include font($sans, $size: size(large), $weight: light, $style: normal);

	@include query(bravo) {
		font-size: size(xlarge);
	}
}

%section-divider {
	@extend %heading;
	color: $gray6;
	letter-spacing: 0;
	line-height: 1.35;
	text-transform: uppercase;

	@include font($sans, $size: size(medium), $weight: bold, $style: normal);

	@include query(bravo) {
		font-size: size(big);
	}
}

%sidekick {
	@extend %heading;
	color: $gray6;
	letter-spacing: 0.07em;
	text-transform: uppercase;

	@include font($sans, $size: size(base), $weight: semibold, $style: normal);
}

%shrimp {
	@extend %heading;
	color: $gray6;

	@include font($sans, $size: size(base), $weight: semibold, $style: normal);
}

%label {
	@extend %heading;
	text-transform: uppercase;

	@include font($sans, $size: size(small), $weight: normal, $style: normal);
}

%snack {
	@extend %heading;
	color: lighten($primary, 10%);
	text-transform: uppercase;

	@include font($sans, $size: size(small), $weight: bold, $style: normal);
}


a %title,
a %section-divider,
a %sidekick,
a %shrimp,
a %label,
a %snack {
	@include themed {
		color: $theme;
	}
}

h1 {
	@extend %title;
	margin: 0.5em 0;
}

h2 {
	@extend %section-divider;
	margin: 0.5em 0;
}

h3 {
	@extend %sidekick;
	margin: 0.5em 0;
}

h4 {
	@extend %shrimp;
	margin: 0.5em 0;
}

h5 {
	@extend %label;
	margin: 0.5em 0;
}

h6 {
	@extend %snack;
	margin: 0.5em 0;
}


// Misc
// ----

%uppercase {
	text-transform: uppercase;
}

/*a[href^="#"] {
  border-bottom-style: none !important;
}*/

hr.sideFade {
	border: 0;
	height: 1px;

	@include background-image(linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0) 100%));
}

.text-right {
	text-align: right;
}

.intro--text {
	margin-left: auto;
	margin-right: auto;
	max-width: 65em;
	padding: 2em 0;
	/*text-align: center;*/

	@include query(bravo) {
		padding: 4em 0;
	}

	@include query(charlie) {
		padding: 8em 0 6em 0;
		text-align: left;
	}
}

.intro--text-header {
	color: $gray5 !important;
	font-size: size(spacing);

	@include query(charlie) {
		float: left;
		margin: 0;
		padding-right: 2.5rem;
		text-align: right;
		width: 30%;
	}
}

.intro--text-body {
	@include query(charlie) {
		border-left: 1px solid $border-color;
		float: left;
		padding-left: 2.5rem;
		width: 70%;
	}
}

.intro--text-body p {
	margin: 0;
}

.intro--text-body p + * {
	margin-top: 1.25em;
}

.body--large {
	font-size: size(big);
}

.body--small {
	font-size: size(small);
}

.member-badge {
	border-radius: 3px;
	color: #fff;
	display: inline-block;
	font-size: 0.875rem;
	font-weight: 400;
	margin-left: 0.25em;
	padding: 0.25em 0.5em;
	text-transform: uppercase;
	vertical-align: middle;

	@include themed {
		background-color: $theme;
	}
}

.main--content h1:first-child {
	margin-top: 0;
}