// Sizes
// =====

$sizes: (
  giant: 5.143em,
  huge: 4em,
  xxlarge: 3.429em,
  xlarge: 2em,
  large: 1.572em,
  big: 1.286em,
  medium: 1.143em,
  base: 1em,
  small: 0.857em,
  spacing: 2.571em
);
