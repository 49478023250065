// Events
// ======

.event--list ul,
.event--list__full ul {
	@extend %unstyled-list;

	margin: 0;
}

.event--list__full {
	margin-bottom: 2em;
}

.event--list__year h6 {
	margin: 2em 0 1em 0;
	overflow: hidden;
	position: relative;
}

.event--list__year h6:after {
	background: $border-color;
	content: "";
	height: 1px;
	left: 3.5em;
	line-height: 0;
	position: absolute;
	top: 50%;
	width: 100%;
}

.event--list__section {
	margin-top: 2em;
}

.event--list__event {
	@extend .clearfix;
	
	margin-bottom: 1em;
	min-height: 4em;
	padding: 0.5em 0 0 5em;
	position: relative;
}

.event--list__full .event--list__event {
	margin-bottom: 2em;
}

.event--list__event .fa {
	@include themed {
		color: $theme;
	}
}

.event--list__event a {
	@extend .clearfix;

	display: block;
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: none;

		@include themed {
			.event--list__title {
				color: $theme-dark;
			}
		}
	}
}

.event--list__date {
	background: #fff;
	border-radius: 3px;
	box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.08);
	color: $gray6;
	left: 0;
    line-height: 0.875;
    padding: 0.5em 0;
	position: absolute;
	text-align: center;
	top: 0;
    width: 3.75em;
}

.event--list__month {
	@extend %sidekick;

	color: $gray6 !important;
	display: block;
}

.event--list__day {
	font-size: size(xlarge);
	font-weight: 700;
}

.event--list__title {
	margin: 0;
}

.event--list__location {
	color: $primary;
	font-size: size(small);
	text-transform: uppercase;
}

.event--list__description p:last-child {
	margin-bottom: 0;
}

.event--detail__buttons {
	margin: 1em 0;
}

.event--detail__buttons .button {
	font-size: 1em;
	margin: 0 0.25em 0.25em 0;
}

.event--detail__buttons .cal-form {
	display: inline-block;
}

.event--detail__speakers {
	margin: 2em 0;
}

.event--detail__speakers .media {
	margin-bottom: 1em;
}

.event--detail__speakers .media .img {
	max-width: 25%;
}