// Main Navigation
// =======

.site--Logo {
	float: left;
	position: relative;

	@include query(bravo) {
		bottom: -35px;
		float: none;
		position: absolute;
	}

	@include query(charlie) {
		bottom: -55px;
		margin-left: size(xlarge);
	}
}

.site--Logo .toggle--MainNav {
	background: #e6e6e6;
	/*position: absolute;*/
	right: 0;
	top: 50%;
	margin-top: -1.5em;
	padding: 0.5em;

	@include themed {
		-webkit-box-shadow: inset 0px -2px 0px 0px $theme;
		-moz-box-shadow: inset 0px -2px 0px 0px $theme;
		box-shadow: inset 0px -2px 0px 0px $theme;
	}
}

.js .main--Navigation {
	display: none;
}

.main--Navigation,
.main--Navigation ul {
	list-style: none;
	padding-left: 0;
	padding-bottom: 0;
	margin: 0;
}

.main--Navigation > ul > li {
	border-bottom: 1px solid #DDD;
}

.main--Navigation .dropdown-menu {
	background-color: #e6e6e6;
}

.main--Navigation .dropdown-panel {
	background-color: #eeeeee;
}

.main--Navigation a {
	display: block;
	padding: 1em 0.5em;
	/*color: #677074 !important;*/
	@include query(bravo) {
		padding: 0.5em 1em;
	}
}

.js .main--Navigation .expandable,
.js .main--Navigation .flyout-expandable {
	display: block;
	position: relative;
}

.js .main--Navigation .expandable .expand,
.js .main--Navigation .flyout-expandable .flyout-expand {
	position: absolute;
	right: 0.5em;
	top: 0.5em;
	cursor: pointer;
}

.js .main--Navigation .expandable .minus,
.js .main--Navigation .active > .expandable .plus,
.js .main--Navigation .dropdown-menu,
.js .main--Navigation .flyout-expandable .minus,
.js .main--Navigation .active > .flyout-expandable .plus,
.js .main--Navigation .dropdown-panel {
	display: none;
}

.js .main--Navigation .expandable .plus,
.js .main--Navigation .active > .expandable .minus,
.js .main--Navigation .active .dropdown-menu,
.js .main--Navigation .flyout-expandable .plus,
.js .main--Navigation .active > .flyout-expandable .minus,
.js .main--Navigation .dropdown-menu .active .dropdown-panel {
	display: block;
}

.js .main--Navigation .expandable a,
.js .main--Navigation .flyout-expandable a,
.main--Navigation a {
	margin-right: 50px;
}

/*.bizGroupExtra li {
font-style: normal;
}*/
.main--Navigation_biz {
	background: #ccc;
	margin: 0;
}

.main--Navigation_biz h4 {
	margin: 0;
	text-align: center;
	padding: .5em;
	background: #363636;
	color: #fff;
}

.main--Navigation_biz li {
	margin-bottom: 0;
}

.main--Navigation h3 {
	margin: 0;
	text-align: center;
	padding: .5em;
}


.main--Navigation {
	border: none;
	padding-top: 1em;

	@include query(bravo) {
		display: block !important;
		margin-left: 6em;
		padding-top: 0;

		li {
			display: table-cell;
			position: relative;
			text-align: center;
      vertical-align: bottom;
		}
	}

	@include query(charlie) {
		margin-left: 11.5em;
	}

	.nav-mod {
		@include themed {
			background: $theme;
			box-shadow: inset 0 1px 1px lighten($theme, 7%), 0 -1px 0 darken($theme, 3%), -1px 0 0 darken($theme, 5%), 1px 0 0 darken($theme, 5%), 0 1px 0 darken($theme, 7%);
			text-shadow: 0 1px 1px darken($theme, 7%);
		}

		margin-bottom: 0;

		li:first-child a {
			border-top: none !important;
		}

		a {
			@include themed {
				color: $overlayText;
			}

			border-top: 1px solid rgba(255,255,255,0.3) !important;
			margin-left: 1em;
			margin-right: 1em;
			-moz-transition: all 200ms ease-out;
			-o-transition: all 200ms ease-out;
			-webkit-transition: all 200ms ease-out;
			transition: all 200ms ease-out;
		}

		@include query(bravo) {
			@include themed {
				background: transparent !important;
				box-shadow: none;
				text-shadow: none;

				a {
					border-radius: 3px;
					border-top: none !important;
					color: $theme;
					margin-left: 0;
					margin-right: 0;
					text-decoration: none;
					text-transform: uppercase;

					@include font($sans, $weight: normal);

					&:hover,
					&:focus {
						background: #fff;
						color: $theme-dark;
					}
				}
			}
		}
	}
}

.nav--wrap {
	clear: both;
}

.logo img {
	display: block;
	width: 70px;

	@include query(charlie) {
		width: 110px;
	}
}

.secondary--nav {
	@extend .clearfix;
	
	@include query(bravo) {
		border-left: 1px solid $gray2;
	}
}

.secondary--nav ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

@include query(bravo) {
	.secondary--nav li {
		float: left;
	}
}

.secondary--nav a {
	@extend %sidekick;
	color: $gray5 !important;
	border-bottom: 1px solid $gray2;
	border-bottom: 1px solid rgba(0,0,0,0.1);
	display: block;
	font-size: 0.833em;
	padding: 1em;
	text-decoration: none;
	-moz-transition: all 200ms ease-out;
	-o-transition: all 200ms ease-out;
	-webkit-transition: all 200ms ease-out;
	transition: all 200ms ease-out;

	@include query(bravo) {
		border-bottom: none;
		border-right: 1px solid $gray2;
		height: 44px;
		padding: 2em 1.5em 1em 1.5em;

		&:hover,
		&:focus {
			background: #fff;
		}
	}
}

/*#off--canvas-menu .secondary--nav a {
	font-size: 1em;
}*/

a.toggle--MainNav {
	float: right;
	margin-top: 1em;
	padding-bottom: 0.75em;
	padding-top: 0.75em;
}

%nav-icon-bar {
	background-color: #fff;
	height: 2px;
	width: 17px;
}

a.toggle--MainNav .nav-icon {
	@extend %nav-icon-bar;
	display: inline-block;
	margin: -3px 0.25em 0 0;
	position: relative;
	vertical-align: middle;
}

a.toggle--MainNav .nav-icon:before,
a.toggle--MainNav .nav-icon:after {
	@extend %nav-icon-bar;
	content: "\0020";
	display: block;
	left: 0;
	position: absolute;
	-moz-transform: translateY(-5px);
	-ms-transform: translateY(-5px);
	-o-transform: translateY(-5px);
	-webkit-transform: translateY(-5px);
	transform: translateY(-5px);
}

a.toggle--MainNav .nav-icon:after {
	-moz-transform: translateY(5px);
	-ms-transform: translateY(5px);
	-o-transform: translateY(5px);
	-webkit-transform: translateY(5px);
	transform: translateY(5px);
}