// News
// ======

.article--list {
  margin-top: 2em;
}

.leftSplitLine {
	border-left: 1px solid $light;
}
