// Global box-sizing
// =================

/**
 * Set the global `box-sizing` to `border-box`.
 *
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 * paulirish.com/2012/box-sizing-border-box-ftw
 */
html {
  box-sizing: border-box;
}

* {
  &,
  &::before,
  &::after {
    box-sizing: inherit;
  }
}
