.speaker-list .speaker {
	@extend .clearfix;

	margin-top: 0;
}

.speaker-list .speaker img {
	max-width: 185px;

	@include query(alpha) {
		float: left;
		margin-right: 2em;
		max-width: 20%;
	}

	@include query(charlie) {
		float: none;
		margin-right: 0;
		max-width: 100%;
	}

}

.speaker-list .speaker.size1of1 img {
	@include query(charlie) {
		float: left;
		margin-right: 2em;
		max-width: 20%;
	}
}

