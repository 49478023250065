// Widths
// ======

@mixin widths($max, $prefix: '', $postfix: '') {
	%#{$prefix}width#{$postfix} {
		/* placeholder */
	}

	@for $denom from 1 through $max {
		@for $num from 1 through $denom {
			$gcd: gcd($num, $denom);

			.#{$prefix}size#{$num}of#{$denom}#{$postfix} {
				@extend %#{$prefix}width#{$postfix};

				@if $gcd > 1 {
					@extend .#{$prefix}size#{$num / $gcd}of#{$denom / $gcd}#{$postfix};
				}
				@else {
					width: percentage($num/$denom) !important;
				}
			}
		}
	}
}

@include widths($columns, $prefix: 'narrow-');

@each $query in alpha, bravo, charlie {
	@include query($query) {
		@include widths($columns, $prefix: $query + '-');
	}
}

@include query(charlie) {
	@include widths($columns);
}
