// Products
// =======

.products > ul {
	/*remove bg color*/
	/*background: #f1f1f1;*/
	padding-top: 1em;
	// margin-right: -20px !important;
}

.products .item {
	/*width: 300px !important;*/
	width: 300px;
	padding-bottom: 0;
	position: relative;
	margin: 5px 0.809em;
}

.products .item .img {
	max-width: 150px;
	margin: 1em auto 0;
	max-height: 120px;
}

.products .gallery-image-caption {
	display: table;
	padding: 1em;
}

.products .gallery-image-caption-title {
	display: table-row;
}

.products .gallery-image-caption p {
	display: table-cell;
	padding-top: 1em;
	vertical-align: bottom;
	width: 100% !important;
}

.products .slick-dots {
	position: static;
}

.products .slick-prev:before,
.products .slick-next:before {
	color: #000;
}

.product--Price, .product--Format {
	display: block;
	margin: 0.25em 0;
	text-transform: uppercase;
}

.product-price-buy {
	background: #fafafa;
	padding: 1em;

	@include query(alpha) {
		display: inline-block;
		padding: 2em 2.5em;
	}
}

.product-price-buy hr {
	margin: 1em 0;

	@include query(alpha) {
		margin: 1.5em 0;
	}
}

.product-price-list {
	@extend .hlist;
	
	margin: 0;

	li {
		margin-right: 1.5em;
	}

	h3 {
		margin-top: 0;
	}
}

.product-price-list + .cart-options {
	border-top: 1px solid $border-color;
	margin-top: 1em;
	padding-top: 1em;

	@include query(alpha) {
		margin-top: 1.25em;
		padding-top: 1.25em;
	}
}

.cart-options .shopping--Cart-quantity {
	margin-bottom: 0.5em;

	@include query(alpha) {
		margin-bottom: 0;
	}
}

.product--Detail {
	margin-top: 30px;

	.unit.img {
		text-align: center;

		@include query(alpha) {
			text-align: left;
		}

		.img-plus {
			margin-top: 1em;
			text-align: left;
		}
	}
}

.product--Detail-counts {
	margin: 0;
}

.product--Detail-counts span {
	text-transform: uppercase;
	display: block;
	font-size: 14px;
}

.product--Detail-stats {
	margin-bottom: 0;
}

.product--Detail .media .img {
	max-width: 100%;
	margin-bottom: 1em;

	@include query(charlie) {
		margin-right: 7rem;
	}
}

.product--Detail .media img {
	display: block;
	margin: 0 auto;
}

@include query(charlie) {
	.product--Detail .media .bd {
		margin-bottom: 1em;
	}
}


.product-format {
	font-size: .8em;
}

.qty {
	padding-bottom: 10px;
}

.qty input[type="text"] {
	width: initial;
}

h1.product-title {
	margin: 0 0 0.75em 0;

	@include query(bravo) {
		border-bottom: 1px solid $border-color;
		padding-bottom: 0.25em;
	}
}

.hd h2.product-title {
	@include themed {
		color: $theme;
	}

	text-align: left;
}

.relatedProducts h4 {
	font-size: 1.06em;
	line-height: 1.2;
}

.sidebarRelated .img-wrap {
	text-align: center;

	@include query(alpha) {
		float: left;
		margin-right: 1em;
	}

	@include query(bravo) {
		float: none;
		margin-right: 0;
	}
}

.sidebarRelated .gallery-image-caption {
	float: none;
	width: auto;
	margin-top: 1em;
	padding: 0;
}

.sidebarRelated .img {
	max-width: 120px;
}
