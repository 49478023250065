// Success Story
// ============

.success--story {
	@extend .clearfix;

	margin-top: 2em;
}

.success--story .unit + .unit {
	margin-top: 0;
}

.successStory--wrapper {
	border-top: 1px solid $border-color;
}

.successStory--wrapper .inner {
    padding-bottom: 1em;
    padding-top: 2em;
	position: relative;

	@include query(bravo) {
		padding-top: 4em;
	}
}

.success--story-media {
	margin: 0;
}

.success--story-mediacaption {
	@extend %label;
	
	padding: 0.5em 1em;
}

.success--story-title {
	@extend %title;
}